import React from 'react';
import { languagePosition_forArray } from './Utils';

export default function Loader() {
    const TextLabel = ["Aguarde por favor", "Please wait"]
    return (
        <div className="ajax_loader_back" style={{ display: "initial" }}>
            <div className="lds-roller" style={{ display: "initial" }}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <span className="lds-roller-text">{TextLabel[languagePosition_forArray]}</span>
            </div>
        </div>);
}