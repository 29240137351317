import React from 'react';
import './One.css';
import './Slider.css';
import { images, languagePosition_forArray } from '../Utils';
import { useHistory } from 'react-router-dom';

export default function One(props) {

    const onlineTransmission= [" ∙ TRANSMISSÃO ONLINE"," ∙ ONLINE STREAMING"];
    const dataObject = props.cards;    
    const history = useHistory()


    const handleGoDetails = (e) => {
        history.push({ pathname: '/details/' + e.id, state: e });
    }

    const IMG1 = images('./background-banner_escuro.jpg');

    return (
        <>
            <div id="banner-front-wrapper">
                <div id="banner-front" style={{ backgroundImage: "url(" + IMG1 + ")" }}>
                    <div className="container">
                        <div id="banner-front-inner">
                            <div id="destaques-home-wrapper">
                                {dataObject.map((event, i) => (
                                    <div key={event.id} className={"one-destaque-home destaque-home active"} onClick={() => handleGoDetails(event)}>

                                            {/*Score Recize
                                             <div className="destaque-img pointer" style={{ background: event.presentationImages !== null ? `url(${event.presentationImages.link + '&r_AR=o&r_W=624'})` : "#ccc", backgroundRepeat: "no-repeat", backgroundPosition: 'center', backgroundSize: 'cover' }}> */}
                                            <div className="destaque-img pointer" style={{ background: event.presentationImages !== null ? `url(${event.presentationImages.link})` : "#ccc", backgroundRepeat: "no-repeat", backgroundPosition: 'center', backgroundSize: 'cover' }}>
                                                <div style={{ height: '100%', background: 'linear-gradient(0deg, rgba(0, 0, 0, 1) 8%, rgba(255, 0, 26, 0) 50%)', position: 'relative' }}>
                                                    {event.hasVideo && event.isStreaming ?
                                                        <div style={{ backgroundColor: "rgb(236, 0, 0, 0.8)", position: 'absolute', width: "100%", padding: "3px 15px", height: '35px' }}>
                                                            <label style={{ margin: "4px 0px 0px 0px", color: "#fff", fontWeight: "600" }}>{event.startDateHourString + onlineTransmission[languagePosition_forArray]}</label>
                                                            <img style={{ float: "right", paddingRight: "5px" }} src={images('./icone-cartão-2.png')} alt="Video" ></img>
                                                        </div>
                                                        : event.hasVideo && event.isStreaming === false ?
                                                            <div style={{ position: 'absolute', width: "100%", padding: "3px 15px", height: '35px' }}>
                                                                <img style={{ float: "right", paddingRight: "5px" }} src={images('./icone-cartão-2.png')} alt="Video" ></img>
                                                            </div>
                                                            : <></>}
                                                    <div className="destaque-home-text-content">
                                                        {event.apresentationStartDate !== "" ? <label className="destaque-mini-home-date">{event.apresentationStartDate + (event.startDateHourString !== "" ? (' ∙ ' + event.startDateHourString) : '')}</label> : <></>}
                                                        <label className="destaque-mini-home-title">{event.name}</label>
                                                        {event.contentCategoryName !== null && event.contentCategoryName !== "" ?
                                                            <label className="destaque-mini-home-desctiprion">{event.contentCategoryName + " | " + event.county}</label> :
                                                            <label className="destaque-mini-home-desctiprion">{event.county}</label>}
                                                    </div>
                                                </div>
                                            </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div id="banner-front-space"></div>
                </div>
            </div>
        </>
    )
}
