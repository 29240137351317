import React, { useEffect, useState } from "react";
import { images } from './Utils';
import { isIOS } from "react-device-detect";

const InstallPWA = () => {
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState(null);

  // Detects if device is in standalone mode
  const isInWebAppiOS  = (window.navigator.standalone === true);

  
  useEffect(() => {
    const handler = e => {
      e.preventDefault();
      setSupportsPWA(true);
      setPromptInstall(e);
    }
    window.addEventListener("beforeinstallprompt", handler);

    return () => window.removeEventListener("beforeinstallprompt", handler);
  }, []);

  const onClick = evt => {
    evt.preventDefault();
    if (isIOS) {
      return(
        alert("Para instalar em IOS, tem de carregar no partilhar e de seguida em \"Ecrã principal\".")
      );
    } else {
      if (!promptInstall) {
        return;
      }
      promptInstall.prompt();
    }
  };
  if (isIOS && isInWebAppiOS) {
    return null;
  }else if(supportsPWA === false && isIOS === false){
    return null;
  }
  return (
    <button
      id="install_button"
      className="bm-item menu-item"
      aria-label="Install app"
      title="Install app"
      onClick={onClick}
    >
      <img src={images('./menu_instalar-aplicação.png')} alt='Install' />
      <span>Instalar aplicação</span>
    </button>
  );
};

export default InstallPWA;