import React from 'react'
import Footer from '../Footer';
import { images, languagePosition_forArray } from '../Utils';
import Header from '../Header';

export default function Contacts() {

    const tilteLabel = ["Contactos", "Contacts"];
    const addressLabel = ["Morada", "Address"];
    const contactsLabel = ["Contactos", "Contacts"];
    const clientHelpLabel = ["Atendimento ao Cliente", "Customer service"];
    const morningLabel = ["Manhã - 9:00 às 12:00", "Morning - 9:00 to 12:00"];
    const afternoonLabel = ["Tarde - 14:00 às 17:00", "Afternoon - 14:00 to 17:00"];
    return (
        <>
            <Header hasTitle={true} title={tilteLabel[languagePosition_forArray]} showSideBar={true} />
            <div className="main">
                <img src={images('./image_contactos.jpg')} alt="contact" style={{ width: "100%", height: '160px' }} />
                <div className="container" style={{marginBottom: '20px'}} >
                    <div className="row">
                        <div className="contact-container col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="contact-item">
                                <img src={images('./icone_contactos_morada.png')} alt="morada" />
                                <label className="contact-item-title">{addressLabel[languagePosition_forArray]}</label>
                                <label>Rua Cruz de Santa Apolónia</label>
                                <label>23, 25 e 25A</label>
                                <label>1100-187 Lisboa</label>
                            </div>
                        </div>
                        <div className="contact-container col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="contact-item">
                                <img src={images('./icone_contactos_contactos.png')} alt="contacto" />
                                <label className="contact-item-title">{contactsLabel[languagePosition_forArray]}</label>
                                <label>T. (+351) 218 428 570</label>
                                <label>F. (+351) 218 428 577</label>
                                <label>E. amlcorreio@aml.pt</label>
                            </div>
                        </div>
                        <div className="contact-container col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="contact-item">
                                <img src={images('./icone_contactos_atendimento.png')} alt="atendimento" />
                                <label className="contact-item-title">{clientHelpLabel[languagePosition_forArray]}</label>
                                <label>{morningLabel[languagePosition_forArray]}</label>
                                <label>{afternoonLabel[languagePosition_forArray]}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
