import axios from 'axios';


const api = axios.create({
    //baseURL: 'https://localhost:44342/ServiceM.svc/json/', //DEV
    //baseURL: 'https://amlmural-demo.noshape.com/ws/serviceM.svc/json/', //DEMO
    baseURL: 'https://www.mural18.pt/ws/serviceM.svc/json/' //PRD
});


export default api;