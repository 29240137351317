import React from 'react';
import { useHistory } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa'
import NavMenu from './NavMenu/NavMenu';
import { images, LanguageID } from './Utils';
import { useState } from 'react';
import SettingsMenu from './SettingsMenu/SettingsMenu';

export default function Header(props) {
    //const images = require.context('../assets', true);
    const customButtons = props.GoBackOnTitle !== undefined;
    const [displayLang, setDisplayLang] = useState(false);
    const currentMenu = window.location.pathname;
 
    const changeLanguage = id => {
        localStorage.setItem('LanguageID', id);
        window.location.reload(false);
    }

    return (
        <>
            <div className="header">
                <div className="container">
                    <div className="container-header" style={{ justifyContent: props.Removebuttons !== true ? "space-between" : "" }}>
                        <div className="container-header-div">
                            {props.showSideBar ? <NavMenu /> : <GoBack />}
                            <a href="/home"><img style={{ cursor: 'pointer', paddingLeft: '15px' }} src={images('./logo.png')} alt="home" /></a>
                        </div>

                        <div>
                            {props.Removebuttons === true ? <></> : <>
                                <div style={{ display: "flex" }} id={'header-buttons'}>
                                    {LanguageID() === 1 ? <img style={{cursor: "pointer" }} src={images('./icone_idioma_pt.png')} onClick={() => setDisplayLang(!displayLang)} alt="language" /> : <></>}
                                    {LanguageID() === 2 ? <img style={{cursor: "pointer" }} src={images('./icone_idioma_en.png')} onClick={() => setDisplayLang(!displayLang)} alt="language" /> : <></>}
                                    {displayLang ?
                                        <div style={{ position: "fixed", zIndex: "10" }}>
                                            <div style={{ zIndex: "11", width: "100%", height: "100%", position: "inherit", left: "0px", top: "0px", }} onClick={() => setDisplayLang(false)}></div>
                                            <div className="language_div" style={{ zIndex: "12" }}>
                                                <div className="language" style={{ zIndex: "12" }}>
                                                    <div className="language_new" onClick={() => changeLanguage(1)}> Português </div>
                                                    <hr style={{ margin: "0px", padding: "0px" }} />
                                                    <div className="language_new" onClick={() => changeLanguage(2)}> English </div>
                                                </div>
                                            </div>
                                        </div> : <></>}

                                    {props.InformationButton === true ?
                                        <a href={'/information'} style={{ marginLeft: "10px" }} >
                                            <img src={images('./icone_info_amarelo.png')} alt="information" />
                                        </a> : <></>}
                                    {currentMenu.includes('mapa') === false ?
                                        props.MapButton !== undefined ? props.MapButton :
                                            <a className={'menu-item'} href={'/mapa'} style={{ marginLeft: "10px" }}>
                                                <img src={images('./icone_mapa_amarelo.png')} alt="map" />
                                            </a>
                                        : <></>
                                    }
                                </div>
                                <div style={{display: 'none'}} id={'buttonsNav'}>
                                <SettingsMenu InformationButton={props.InformationButton} MapNavButton={props.MapNavButton} currentMenu={currentMenu} />
                                </div>
                            </>}
                        </div>

                    </div>


                </div>
            </div>
            {
                props.hasTitle ?
                    <div className="header-menu">
                        <div className="container">
                            <label>
                                {/*<HomeBreadCrumb />
                                props.subMenu ? 
                                 <SubBreadCrumb submenu={props.subMenu}/>
                                : <></>*/}
                                {props.title}
                            </label>
                            {
                                customButtons ?
                                    <GoBack />
                                    : ''
                            }
                        </div>
                    </div>
                    : ''
            }


        </>
    );
}


const GoBack = () => {
    const history = useHistory();

    return (
        <>
            <div className="pointer shadow-white" onClick={() => history.goBack()}>
                <FaArrowLeft size={20} color="#000000" />
            </div>
        </>
    );
}

/*const HomeBreadCrumb = () => {
    const history = useHistory();
    return (
        <span onClick={() => history.push('/home')} style={{ cursor: "pointer", fontWeight:"bold" }}>Home &gt; </span>
    )
}

const SubBreadCrumb = props => {
    const history = useHistory();
    console.log(props.submenu)
    return (
        props.submenu.map((sub, index) =>
            <span key={index} onClick={() => history.push(sub.url)} style={{ cursor: "pointer" }}>{sub.name} &gt; </span>
        )
    )
}*/