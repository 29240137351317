import React, { useState } from 'react';
import { slide as Menu } from 'react-burger-menu'
import { FiMoreVertical } from 'react-icons/fi';
import { images, LanguageID, languagePosition_forArray } from '../Utils';

import './SettingsMenu.css'

export default function SettingsMenu(props) {

    const [menuOpen, setMenuOpen] = useState(false);
    const language = ['Idioma', 'Language'];
    const labelInformation = ['Informação', 'Information'];
    const labelMap = ['Mapa', 'Map'];
    const [displayLang, setDisplayLang] = useState(false);

    const changeLanguage = id => {
        localStorage.setItem('LanguageID', id);
        window.location.reload(false);
    }

    return (<>
        <FiMoreVertical size={35} style={{ cursor: 'pointer', }} onClick={() => setMenuOpen(true)} />
        <Menu id={"SettingsMenu"}
            right isOpen={menuOpen} width={340} onClose={() => setMenuOpen(false)}>
            <div className="menu-title" style={{borderBottom: '1px solid #ffed00'}}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img style={{ paddingLeft: '15px' }} src={images('./logo.png')} alt="Logo" />
                    <label></label>
                    <img onClick={() => setMenuOpen(false)} style={{ height: '26px', width: '27px', cursor: 'pointer' }} src={images('./icone_close_amarelo.png')} alt="Fechar" />
                </div>
            </div>


            <div className={'menu-item '} style={{ cursor: "pointer" }} onClick={() => setDisplayLang(!displayLang)}>
                {LanguageID() === 1 ? <img src={images('./icone_idioma_pt.png')} alt="language" /> : <></>}
                {LanguageID() === 2 ? <img src={images('./icone_idioma_en.png')} alt="language" /> : <></>}
                <span>{language[languagePosition_forArray]}</span>
            </div>
            {displayLang ?
                <div style={{ position: "fixed", zIndex: "10" }}>
                    <div style={{ zIndex: "11", width: "100%", height: "100%", position: "inherit", left: "0px", top: "0px", }} onClick={() => setDisplayLang(false)}></div>
                    <div className="language_div" style={{ zIndex: "12" }}>
                        <div className="language" style={{ zIndex: "12", top: '-10px', left: '10px' }}>
                            <div className="language_new" onClick={() => changeLanguage(1)}> Português </div>
                            <hr style={{ margin: "0px", padding: "0px" }} />
                            <div className="language_new" onClick={() => changeLanguage(2)}> English </div>
                        </div>
                    </div>
                </div> : <></>}

            {props.InformationButton === true ?
                <a className={'menu-item'} href={'/information'}>
                    <img src={images('./icone_info_amarelo.png')} alt="information" />
                    <span>{labelInformation[languagePosition_forArray]}</span>
                </a> : <></>}

            {props.currentMenu.includes('mapa') === false ?
                props.MapNavButton !== undefined ? props.MapNavButton :
                    <a className={'menu-item'} href={'/mapa'}>
                        <img src={images('./icone_mapa_amarelo.png')} alt="map" />
                        <span>{labelMap[languagePosition_forArray]}</span>
                    </a>
                    : <></>
            }

        </Menu>
    </>
    );
}
