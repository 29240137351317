import React from 'react'
import Footer from '../Footer';
import Header from '../Header';
import Slider from '../Slider/Slider';
import Loader from '../Loader';
import { withRouter } from 'react-router-dom';
import { images, languagePosition_forArray, menus } from '../Utils';
import { isMobile } from "react-device-detect";
import { GetMini_ForFront_Paginate, GetHighlightMini_ForFront, ContentCategoryInfoFO_GetAll, CountyInfo_GetAll } from '../Utils';
import Filters from '../Filter';
import One from '../Slider/One';



class Montra extends React.Component {
    state = {
        errorHigilight: ["Não foi possivel obter os destaques.","It was not possible to get the highlights."],
        errorCounty: ["Não foi possivel obter os municípios.","It was not possible to get the countys."],
        errorCategories: ["Não foi possivel obter as categorias.","It was not possible to get the categories."],
        errorContent: ["Não foi possivel obter conteúdos.","It was not possible to obtain content."],
        onlineTransmission: [" ∙ TRANSMISSÃO ONLINE"," ∙ ONLINE STREAMING"],
        seeMore: ["VER MAIS","MORE"],
        noResults: ["Não existem resultados disponíveis.","There are no results available."],



        menuObject: menus.filter((e) => e.url === this.props.location.pathname)[0],
        loading: true,
        hilight_Cards: null,
        content_Cards: null,
        category: null,
        county: null,
        filter_date: (menus.filter((e) => e.url === this.props.location.pathname)[0].id === parseInt(localStorage.getItem('MenuID'))) !== null ? (localStorage.getItem('filter_date') !== null ? JSON.parse(localStorage.getItem('filter_date')) : null) : null,
        filter_Order: (menus.filter((e) => e.url === this.props.location.pathname)[0].id === parseInt(localStorage.getItem('MenuID'))) !== null ? (localStorage.getItem('filter_Order') !== null ? localStorage.getItem('filter_Order') : (menus.filter((e) => e.url === this.props.location.pathname)[0].id === 12 ? "2" : "1")) : (menus.filter((e) => e.url === this.props.location.pathname)[0].id === 12 ? "2" : "1"),
        filter_OrderColumn: (menus.filter((e) => e.url === this.props.location.pathname)[0].id === parseInt(localStorage.getItem('MenuID'))) !== null ? (localStorage.getItem('filter_OrderColumn') !== null ? localStorage.getItem('filter_OrderColumn') : ((menus.filter((e) => e.url === this.props.location.pathname)[0].id === 1 || menus.filter((e) => e.url === this.props.location.pathname)[0].id === 12) ? "startDate" : "name")) : ((menus.filter((e) => e.url === this.props.location.pathname)[0].id === 1 || menus.filter((e) => e.url === this.props.location.pathname)[0].id === 12) ? "startDate" : "name"),
        filter_OrderDesc: (menus.filter((e) => e.url === this.props.location.pathname)[0].id === parseInt(localStorage.getItem('MenuID'))) !== null ? (localStorage.getItem('filter_OrderDesc') !== null ? localStorage.getItem('filter_OrderDesc') : (menus.filter((e) => e.url === this.props.location.pathname)[0].id === 12 ? "true" : "false")) : (menus.filter((e) => e.url === this.props.location.pathname)[0].id === 12 ? "true" : "false"),
        filter_category: (menus.filter((e) => e.url === this.props.location.pathname)[0].id === parseInt(localStorage.getItem('MenuID'))) !== null ? (localStorage.getItem('filter_category') !== null ? localStorage.getItem('filter_category') : "0") : "0",
        filter_county: (menus.filter((e) => e.url === this.props.location.pathname)[0].id === parseInt(localStorage.getItem('MenuID'))) !== null ? (localStorage.getItem('filter_county') !== null ? localStorage.getItem('filter_county') : "0") : "0",
        filter_search: (menus.filter((e) => e.url === this.props.location.pathname)[0].id === parseInt(localStorage.getItem('MenuID'))) !== null ? (localStorage.getItem('filter_search') !== null ? localStorage.getItem('filter_search') : "") : "",
        ignoreCache: (menus.filter((e) => e.url === this.props.location.pathname)[0].id === parseInt(localStorage.getItem('MenuID'))) !== null ? false : true,
    }

    componentDidMount() {
        GetHighlightMini_ForFront(this.state.menuObject.id).then(result => {
            if (result.status === 200) {
                this.setState({
                    hilight_Cards: result.data.GetHighlightMini_ForFrontResult,
                });
            } else {
                alert(this.state.errorHigilight[languagePosition_forArray]);
            }
        });

        CountyInfo_GetAll().then(result => {
            if (result.status === 200) {
                this.setState({
                    county: result.data.CountyInfo_GetAllResult,
                });
            } else {
                alert(this.state.errorCounty[languagePosition_forArray])
            }
        });

        ContentCategoryInfoFO_GetAll(this.state.menuObject.id).then(result => {
            if (result.status === 200) {
                this.setState({
                    category: result.data.ContentCategoryInfoFO_GetAllResult,
                });
            } else {
                alert(this.state.errorCategories[languagePosition_forArray])
            }
        });

        this.getContent(
            0,
            this.state.filter_OrderColumn,
            this.state.filter_OrderDesc,
            this.state.filter_category,
            this.state.filter_county,
            this.state.filter_date !== null ? this.state.filter_date[0] : "",
            this.state.filter_date !== null ? this.state.filter_date[1] : "",
            this.state.filter_search,
            this.ignoreCache);

        window.addEventListener('scroll', this.onSwiped);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onSwiped);
    }

    handleRowClicked = (e) => {
        localStorage.setItem('Content', JSON.stringify(this.state.content_Cards));
        localStorage.setItem('filter_category', this.state.filter_category);
        localStorage.setItem('filter_county', this.state.filter_county);
        localStorage.setItem('filter_date', JSON.stringify(this.state.filter_date));
        localStorage.setItem('filter_Order', this.state.filter_Order);
        localStorage.setItem('filter_OrderColumn', this.state.filter_OrderColumn);
        localStorage.setItem('filter_OrderDesc', this.state.filter_OrderDesc);
        localStorage.setItem('filter_search', this.state.filter_search);
        localStorage.setItem('MenuID', this.state.menuObject.id);

        this.props.history.push({ pathname: '/details/' + e.id, state: e });
    }

    getContentFiltred_setCategory = (category) => {
        this.setState({ filter_category: category, loading: true });
        this.getContent(
            0,
            this.state.filter_OrderColumn,
            this.state.filter_OrderDesc,
            category,
            this.state.filter_county,
            this.state.filter_date !== null ? this.state.filter_date[0] : "",
            this.state.filter_date !== null ? this.state.filter_date[1] : "",
            this.state.filter_search,
            this.ignoreCache);
    }

    getContentFiltred_setCounty = (county) => {
        this.setState({ filter_county: county, loading: true });
        this.getContent(
            0,
            this.state.filter_OrderColumn,
            this.state.filter_OrderDesc,
            this.state.filter_category,
            county,
            this.state.filter_date !== null ? this.state.filter_date[0] : "",
            this.state.filter_date !== null ? this.state.filter_date[1] : "",
            this.state.filter_search,
            this.ignoreCache);
    }

    getContentFiltred_setDate = (date) => {
        this.setState({ filter_date: date });
        this.getContent(
            0,
            this.state.filter_OrderColumn,
            this.state.filter_OrderDesc,
            this.state.filter_category,
            this.state.filter_county,
            date !== null ? date[0] : "",
            date !== null ? date[1] : "",
            this.state.filter_search,
            this.ignoreCache);
    }

    getContentFiltred_setSearch = (search) => {
        this.setState({ filter_search: search });
        this.getContent(
            0,
            this.state.filter_OrderColumn,
            this.state.filter_OrderDesc,
            this.state.filter_category,
            this.state.filter_county,
            this.state.filter_date !== null ? this.state.filter_date[0] : "",
            this.state.filter_date !== null ? this.state.filter_date[1] : "",
            search,
            this.ignoreCache);
    }

    getContentFiltred_short = (id, column, desc) => {
        this.setState({
            filter_Order: id,
            filter_OrderColumn: column,
            filter_OrderDesc: desc,
            loading: true
        });
        this.getContent(
            0,
            column,
            desc,
            this.state.filter_category,
            this.state.filter_county,
            this.state.filter_date !== null ? this.state.filter_date[0] : "",
            this.state.filter_date !== null ? this.state.filter_date[1] : "",
            this.state.filter_search,
            this.ignoreCache);
    }

    handleMoreCards = () => {
        this.setState({ loading: true });
        GetMini_ForFront_Paginate(
            this.state.menuObject.id,
            this.state.content_Cards.length > 0 ? this.state.content_Cards[this.state.content_Cards.length - 1].id : 0,
            this.state.filter_OrderColumn,
            this.state.filter_OrderDesc,
            this.state.filter_category,
            this.state.filter_county,
            this.state.filter_date !== null ? this.state.filter_date[0] : "",
            this.state.filter_date !== null ? this.state.filter_date[1] : "",
            this.state.filter_search)
            .then(result => {
                if (result !== "erro") {
                    this.setState({
                        content_Cards: this.state.content_Cards.concat(result),
                        loading: false,
                        noMoreResults: result.length !== 9
                    });
                } else {
                    this.setState({
                        loading: false,
                    });
                    alert(this.state.errorContent[languagePosition_forArray])
                }
            }).then(() => {
                this.ClearStorage();
            });
    }

    getContent = (resultsAfterID, sortFieldName, sortDesc, filterCategoryID, filterCountyID, filterStartDate, filterEndDate, filter_search, ignoreCache) => {
        GetMini_ForFront_Paginate(this.state.menuObject.id, resultsAfterID, sortFieldName, sortDesc, filterCategoryID, filterCountyID, filterStartDate, filterEndDate, filter_search, ignoreCache)
            .then(result => {
                if (result !== "erro") {
                    this.setState({
                        content_Cards: result,
                        loading: false,
                        noMoreResults: result.length !== 9
                    });
                } else {
                    this.setState({
                        loading: false,
                    });
                    alert(this.state.errorContent[languagePosition_forArray])
                }
            }).then(() => {
                this.ClearStorage();
            });
    }

    onSwiped = () => {
        if (isMobile) {
            if (window.innerHeight + document.documentElement.scrollTop >= document.scrollingElement.scrollHeight - 3) {
                if (this.state.noMoreResults === false && this.state.loading === false) {
                    this.handleMoreCards();
                }
            }
        }
    }

    ClearStorage = () => {
        localStorage.removeItem("Content");
        localStorage.removeItem('filter_date');
        localStorage.removeItem('filter_Order');
        localStorage.removeItem('filter_OrderColumn');
        localStorage.removeItem('filter_OrderDesc');
        localStorage.removeItem('filter_category');
        localStorage.removeItem('filter_county');
        localStorage.removeItem('filter_search');
        localStorage.removeItem('MenuID');
    }

    render() {
        return (
            <>
                <Header hasTitle={true} title={this.state.menuObject.name[languagePosition_forArray]} showSideBar={true} GoBackOnTitle={'/home'} />
                <div className="main">
                    {this.state.hilight_Cards !== null ? this.state.hilight_Cards.length === 1 ? <One cards={this.state.hilight_Cards} /> : <Slider cards={this.state.hilight_Cards} /> : <></>}
                    {this.state.loading ? <Loader /> : <></>}
                    <div className="container montra-card-wrapper">
                        <Filters
                            county={this.state.county} filter_county={this.state.filter_county} setCounty={(value) => this.getContentFiltred_setCounty(value)}
                            category={this.state.category} filter_category={this.state.filter_category} setCategory={(value) => this.getContentFiltred_setCategory(value)}
                            date={this.state.filter_date} setDate={(date) => this.getContentFiltred_setDate(date)}
                            filterOrder={this.state.filter_Order} setFilterOrder={(id, column, desc) => this.getContentFiltred_short(id, column, desc)}
                            filterOrderColumn={this.state.filter_OrderColumn}
                            filter_search={this.state.filter_search} setSearch={(value) => this.getContentFiltred_setSearch(value)}
                            menuID={this.state.menuObject.id} archived={this.state.menuObject.id === 12}
                        />

                        {this.state.content_Cards !== null && this.state.content_Cards.length > 0 ?
                            <div className="row montra-card-div">
                                {this.state.content_Cards.map((card) => (
                                    <div key={card.id} className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 montra-card-child-wrapper">
                                        <button className="montra-card-child" onClick={() => this.handleRowClicked(card)}>
                                            <div className="montra-card">
                                                <div className="row montra-card-shadow">
                                                    <div className="montra-card-img col-xl-12 col-lg-12 col-md-12 col-sm-6 col-4"
                                                        style={{ background: card.presentationImages !== null && card.presentationImages !== "" ? `url(${card.presentationImages.link + '&r_AR=o&r_W=328'})` : "#ccc", backgroundRepeat: "no-repeat", backgroundPosition: 'center', backgroundSize: 'cover' }}>
                                                        {card.hasVideo && card.isStreaming ?
                                                            <div className="video-alert-div streaming">
                                                                <label>{card.startDateHourString + this.state.onlineTransmission[languagePosition_forArray]}</label>
                                                                <img src={images('./icone-cartão-2.png')} alt="Video" ></img>
                                                            </div>
                                                            : card.hasVideo && card.isStreaming === false ?
                                                                <div className="video-alert-div">
                                                                    <img src={images('./icone-cartão-2.png')} alt="Video" ></img>
                                                                </div>
                                                                : <></>}
                                                    </div>
                                                    <div className="montra-card-label col-xl-12 col-lg-12 col-md-12 col-sm-6 col-8">
                                                        <label className="montra-card-label-title">{card.name}</label>
                                                        <label className="montra-card-label-date">{card.startDateString}</label>
                                                        <label className="montra-card-label-local">{card.county}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </button>
                                    </div>
                                ))}
                                {this.state.noMoreResults ? <></> : isMobile === false ?
                                    <div className="montra-more-cards col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12" onClick={this.handleMoreCards}>
                                        <div className="montra-more-cards-button">
                                            <span>{this.state.seeMore[languagePosition_forArray]}</span>
                                        </div>
                                    </div>
                                    : <></>
                                }
                            </div>
                            :
                            <div style={{ textAlign: "center", padding: "30px 0px" }}>
                                <img src={images('./icone_sem-resultados.png')} alt="Não existem resultados disponíveis" />
                                <label style={{ color: "#a3a3a3", fontSize: "18px", width: "100%", paddingTop: "15px" }}>{this.state.noResults[languagePosition_forArray]}</label>
                            </div>
                        }
                    </div>
                </div>
                <Footer />
            </>
        );
    }
}




export default withRouter(Montra);