import React, { useState, useEffect } from 'react';
import { Swipeable } from "react-swipeable";
import './Slider.css';
import { time, images, GetHighlightMini_ForFront_Paginate, languagePosition_forArray } from '../Utils';
import { useHistory } from 'react-router-dom';
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';
import Loader from '../Loader';

export default function Slider(props) {

    const errorContent= ["Não foi possivel obter conteúdos.","It was not possible to obtain content."];
    const scheduleLabel= ["AGENDA","SCHEDULE"];
    const onlineTransmission= [" ∙ TRANSMISSÃO ONLINE"," ∙ ONLINE STREAMING"];

    const [dataObject, setDataObject] = useState(props.cards);
    const [dataObjectAll, setDataObjectAll] = useState(props.cards);
    const isHomePage = props.homepage;
    const [page, setPage] = useState(1)
    const history = useHistory()
    const [active, setActive] = useState(0);
    const [loader, setLoader] = useState(false);
    const [canLoadMore, setCanLoadMore] = useState(true);

    useEffect(() => {
        const next = (active + 1) % dataObject.length;
        const id = setTimeout(() => setActive(next), time);
        return () => clearTimeout(id);
    }, [active, dataObject.length]);

    const onSwiped = (side) => {
        if (side === 'RIGHT') {
            setActive((active + 1) % dataObject.length);
        } else {
            if (active === 0) {
                setActive(dataObject.length - 1);
            } else {
                setActive(active - 1);
            }
        }
    }

    const changeActiveSlide = (position) => {
        setActive(position);
    }

    const handleGoDetails = (e) => {
        history.push({ pathname: '/details/' + e.id, state: e });
    }

    const handlerGoBack = () => {
        if (page > 1) {
            var maxOb = (page - 1) * 4;
            var minOb = maxOb - 4;
            setDataObject(dataObjectAll.slice(minOb, maxOb));
            setPage(page - 1);
            setActive(0);
            setCanLoadMore(true);
        }
    }

    const handlerGoNext = () => {
        setLoader(true);
        var maxOb = (page + 1) * 4;
        var minOb = maxOb - 4;
        var ob = dataObjectAll.slice(minOb, maxOb);

        if (ob.length > 0) {
            setDataObject(ob);
            setPage(page + 1);
            setLoader(false);
            if (ob.length > 3) {
                setCanLoadMore(true);
            } else {
                setCanLoadMore(false);
            }
        }
        else {
            var resultsAfterID = dataObject[dataObject.length - 1].id;

            GetHighlightMini_ForFront_Paginate(resultsAfterID).then(result => {
                if (result.status === 200) {
                    if (result.data.GetHighlightMini_ForFront_PaginateResult.length > 0) {
                        setDataObjectAll(dataObjectAll.concat(result.data.GetHighlightMini_ForFront_PaginateResult));
                        setDataObject(result.data.GetHighlightMini_ForFront_PaginateResult);
                        setPage(page + 1);
                        setLoader(false);
                        if (result.data.GetHighlightMini_ForFront_PaginateResult.length > 3) {
                            setCanLoadMore(true);
                        } else {
                            setCanLoadMore(false);
                        }
                    }else{
                        setCanLoadMore(false);
                    }
                } else {
                    alert(errorContent[languagePosition_forArray]);
                }
            });
        }
        setActive(0);
    }

    const IMG1 = images('./background-banner_escuro.jpg');

    return (
        <>
            {loader ? <Loader /> : <></>}
            <div id="banner-front-wrapper">
                <div id="banner-front" style={{ backgroundImage: "url(" + IMG1 + ")" }}>
                    <div className="container">
                        <div id="banner-front-inner">
                            <div id="destaques-home-wrapper" className={isHomePage ? "destaques-home-wrapper-homepage" : ""}>
                                {isHomePage ?
                                    <div id="getMore-mini-div">
                                        <label style={{ margin: "0px", color: "#fff", fontWeight: "600" }}>{scheduleLabel[languagePosition_forArray]}</label>
                                        <div style={{ display: 'flex' }}>
                                            {page === 1 ?
                                                <div style={{ marginRight: "10px" }}> <IoIosArrowBack size={16} color={"#a3a3a3"} /> </div> :
                                                <div style={{ marginRight: "10px", cursor: 'pointer' }} onClick={handlerGoBack}>
                                                    <IoIosArrowBack size={16} color={"#fff"} />
                                                </div>
                                            }
                                            {canLoadMore === true ?
                                                <div style={{ cursor: 'pointer' }} onClick={handlerGoNext}>
                                                    <IoIosArrowForward size={16} color={"#fff"} />
                                                </div> :
                                                <div> <IoIosArrowForward size={16} color={"#a3a3a3"} /> </div>}
                                        </div>
                                    </div> : <></>}
                                {dataObject.map((event, i) => (
                                    <div key={event.id} className={"destaque-home " + (active === i ? " active " : "") + (isHomePage ? "destaque-home-homepage" : "")} onClick={() => handleGoDetails(event)}>
                                        <Swipeable
                                            onSwipedLeft={() => onSwiped('RIGHT')}
                                            onSwipedRight={() => onSwiped('LEFT')}
                                        >
                                            <div className="destaque-img pointer" style={{ background: event.presentationImages !== null ? `url(${event.presentationImages.link + '&r_AR=o&r_W=624'})` : "#ccc", backgroundRepeat: "no-repeat", backgroundPosition: 'center', backgroundSize: 'cover' }}>

                                                <div style={{ height: '100%', background: 'linear-gradient(0deg, rgba(0, 0, 0, 1) 8%, rgba(255, 0, 26, 0) 50%)', position: 'relative' }}>
                                                    {event.hasVideo && event.isStreaming ?
                                                        <div style={{ backgroundColor: "rgb(236, 0, 0, 0.8)", position: 'absolute', width: "100%", padding: "3px 15px", height: '35px' }}>
                                                            <label style={{ margin: "4px 0px 0px 0px", color: "#fff", fontWeight: "600" }}>{event.startDateHourString + onlineTransmission[languagePosition_forArray]}</label>
                                                            <img style={{ float: "right", paddingRight: "5px" }} src={images('./icone-cartão-2.png')} alt="Video" ></img>
                                                        </div>
                                                        : event.hasVideo && event.isStreaming === false ?
                                                            <div style={{ position: 'absolute', width: "100%", padding: "3px 15px", height: '35px' }}>
                                                                <img style={{ float: "right", paddingRight: "5px" }} src={images('./icone-cartão-2.png')} alt="Video" ></img>
                                                            </div>
                                                            : <></>}
                                                    <div className="destaque-home-text-content">
                                                        {event.apresentationStartDate !== "" ? <label className="destaque-mini-home-date">{event.apresentationStartDate + (event.startDateHourString !== "" ? (' ∙ ' + event.startDateHourString) : '')}</label> : <></>}
                                                        <label className="destaque-mini-home-title">{event.name}</label>
                                                        {event.contentCategoryName !== null && event.contentCategoryName !== "" ?
                                                            <label className="destaque-mini-home-desctiprion">{event.contentCategoryName + " | " + event.county}</label> :
                                                            <label className="destaque-mini-home-desctiprion">{event.county}</label>}
                                                    </div>
                                                </div>
                                            </div>
                                        </Swipeable>
                                    </div>
                                ))}
                                <div id="previews" className={isHomePage ? "previews-homepage" : ""}>
                                    {isHomePage ?
                                        <div id="getMore-div">
                                            <label style={{ margin: "0px", color: "#fff", fontWeight: "600" }}>{scheduleLabel[languagePosition_forArray]}</label>
                                            <div style={{ display: 'flex' }}>
                                                {page === 1 ?
                                                    <div style={{ marginRight: "10px" }}> <IoIosArrowBack size={16} color={"#a3a3a3"} /> </div> :
                                                    <div style={{ marginRight: "10px", cursor: 'pointer' }} onClick={handlerGoBack}>
                                                        <IoIosArrowBack size={16} color={"#fff"} />
                                                    </div>
                                                }
                                                {canLoadMore ?
                                                    <div style={{ cursor: 'pointer' }} onClick={handlerGoNext}>
                                                        <IoIosArrowForward size={16} color={"#fff"} />
                                                    </div> :
                                                    <div> <IoIosArrowForward size={16} color={"#a3a3a3"} /> </div>}
                                            </div>
                                        </div>
                                        : <></>}
                                    {dataObject.map((event, i) => (
                                        <div key={event.id} style={{ height: isHomePage ? "92.6px" : "101.6px" }} className={active === i ? "destaque-mini-home active" : "destaque-mini-home"} onClick={() => changeActiveSlide(i)}>
                                            <div className="text-content">
                                                {event.apresentationStartDate !== "" ? <label className="destaque-mini-date">{event.apresentationStartDate + (event.startDateHourString !== "" ? (' ∙ ' + event.startDateHourString) : '')}</label> : <></>}
                                                <label className="destaque-mini-title">{event.name}</label>
                                                {event.contentCategoryName !== null && event.contentCategoryName !== "" ?
                                                    <label className="destaque-mini-desctiprion">{event.contentCategoryName + " | " + event.county}</label> :
                                                    <label className="destaque-mini-desctiprion">{event.county}</label>}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="banner-front-space"></div>
                </div>
            </div>
        </>
    )
}
