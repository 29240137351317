import React from 'react'
import { FaRegCopyright } from 'react-icons/fa'
import { images, appLink, languagePosition_forArray } from './Utils';
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton,

    // Comment to sepaate, overwriting codesandbox behavior
    FacebookIcon,
    TwitterIcon,
    LinkedinIcon,
    WhatsappIcon,
} from "react-share";

export default function Footer() {
    const label = [" 2020 AML//todos os direitos reservados", " 2020 AML//all rights reserved"]; 

    return (
        <footer className="footer">
            <div className="footer-line"></div>
            <div className="footer-content">
                <div className="container">
                    <div>
                        <FaRegCopyright color={"#ffffff"} />
                        <span>{label[languagePosition_forArray]}</span>
                        <div id="footer-share">
                            <FacebookShareButton
                                url={appLink}
                                quote={"Mural 18"}>
                                <FacebookIcon size={30} bgStyle={{fill: "#bfbfbf"}} iconFillColor="#000" />
                            </FacebookShareButton>
                            <LinkedinShareButton
                                url={appLink}
                                title={"Mural 18"}
                                source="Mural 18">
                                <LinkedinIcon size={30} bgStyle={{fill: "#bfbfbf"}} iconFillColor="#000" />
                            </LinkedinShareButton>
                            <TwitterShareButton
                                url={appLink}
                                title={"Mural 18"}>
                                <TwitterIcon size={30} bgStyle={{fill: "#bfbfbf"}} iconFillColor="#000" />
                            </TwitterShareButton>
                            <WhatsappShareButton
                                url={appLink}
                                title={"Mural 18"}>
                                <WhatsappIcon size={30} bgStyle={{fill: "#bfbfbf"}} iconFillColor="#000"/>
                            </WhatsappShareButton>
                        </div>
                    </div>
                    <div className="footer-logo">
                        <img src={images('./logo-aml_footer.png')} alt="Logo AML" />
                    </div>
                    <div className="footer-logo">
                        <img src={images('./logos-2020.png')} alt="Logos 2020" />
                    </div>
                </div>
            </div>

        </footer>
    )
}