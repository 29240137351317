import React from 'react';
import Footer from './Footer';
import Header from './Header';
import { LanguageID, languagePosition_forArray } from './Utils';

export default function Information() {

    const titleLabel = ["Informação","Information"];

    return (
        <>
            <Header hasTitle={true} title={titleLabel[languagePosition_forArray]} showSideBar={true} GoBackOnTitle={'/home'} />
            <div className="container" style={{ marginTop: '20px', textAlign: 'justify', textJustify: 'inter-word' }}>
                {LanguageID() === 1 ? infoPT() : ""}
                {LanguageID() === 2 ? infoEN() : ""}
            </div>
            <Footer />
        </>
    );
}

const infoPT = () => {
    return (
        <>
            <p><strong>Projeto Mural 18</strong></p>
            <p>O Mural 18 &eacute; um projeto desenvolvido pela &Aacute;rea Metropolitana de Lisboa no &acirc;mbito do aviso do Programa Operacional Regional de Lisboa 2020 &ndash; Programa&ccedil;&atilde;o Cultural em Rede.</p>
            <p>O objetivo consiste em aumentar a atratividade e dinamizar as estrat&eacute;gias de desenvolvimento tur&iacute;stico da regi&atilde;o e a sua dinamiza&ccedil;&atilde;o econ&oacute;mica, com vista &agrave; diminui&ccedil;&atilde;o de assimetrias e ao refor&ccedil;o da coes&atilde;o territorial, por via da realiza&ccedil;&atilde;o de eventos associados ao patrim&oacute;nio, &agrave; cultura e a bens culturais, desenvolvendo uma programa&ccedil;&atilde;o em rede a n&iacute;vel intermunicipal.</p>
            <p>O projeto Mural 18 desenvolve-se nos seguintes dom&iacute;nios:</p>
            <p>- Programa&ccedil;&atilde;o cultural;<br />- 18 murais de arte urbana;<br />- F&oacute;rum de reflex&atilde;o;<br />- Plataforma digital.</p>
            <p>Com in&iacute;cio no dia 15 de janeiro em todos os 18 concelhos desenvolve-se uma programa&ccedil;&atilde;o cultural em v&aacute;rias vertentes art&iacute;sticas como a M&uacute;sica, o Teatro, o Cinema, a Dan&ccedil;a, a Leitura, Pintura, Fotografia, Exposi&ccedil;&otilde;es, entre outras.</p>
            <p>Esta programação estender-se-á até ao mês de dezembro.</p>
            <p>S&atilde;o mais de duzentos espet&aacute;culos que se v&atilde;o realizar na maior parte das vezes, em espa&ccedil;os ao ar livre valorizando o vasto patrim&oacute;nio natural e cultural da regi&atilde;o.</p>
            <p>Ser&atilde;o criados 18 murais de arte urbana atrav&eacute;s de interven&ccedil;&otilde;es de 18 artistas locais entre os meses de mar&ccedil;o e maio.</p>
            <p>Cada munic&iacute;pio indicar&aacute; um artista, posteriormente ser&atilde;o sorteados para intervirem no territ&oacute;rio em outro concelho diferente da sua proveni&ecirc;ncia.</p>
            <p>Ser&aacute; solicitado a cada artista uma interven&ccedil;&atilde;o que transmita a sua vis&atilde;o da fus&atilde;o entre a cultura, a identidade e a viv&ecirc;ncia metropolitana da regi&atilde;o.</p>
            <p>O projeto Mural 18 contempla a realiza&ccedil;&atilde;o de um F&oacute;rum de reflex&atilde;o sobre temas ligados &agrave; cultura.</p>
            <p>Desde j&aacute; anunciamos dois momentos:</p>
            <p>Um primeiro a realizar no mês de outubro, na cidade de Setúbal.</p>
            <p>Iremos refletir sobre &ldquo;Cultura e Sociedade Contempor&acirc;nea &ndash; que papel para a Administra&ccedil;&atilde;o Local?&rdquo;.</p>
            <p>Atrav&eacute;s de uma vis&atilde;o institucional: O Poder Local versus Cultura.</p>
            <p>Atrav&eacute;s de uma vis&atilde;o dos agentes culturais: Contributos para Estrat&eacute;gias de interven&ccedil;&atilde;o local.</p>
            <p>Um segundo momento acontece no mês de dezembro na cidade de Lisboa.</p>
            <p>O mote ser&aacute; &ldquo;Programa&ccedil;&atilde;o cultural em rede&rdquo;.</p>
            <p>Aqui mais uma vez a vis&atilde;o institucional: Experi&ecirc;ncias em rede &ndash; Balan&ccedil;o e desafios.</p>
            <p>E a vis&atilde;o dos agentes culturais: Uma Cultura, uma identidade e a viv&ecirc;ncia metropolitana.</p>
            <p>A plataforma digital onde nos encontramo-nos pretende ser o ponto de encontro da acessibilidade &agrave; cultura e &agrave;s artes no territ&oacute;rio da &aacute;rea metropolitana de Lisboa.</p>
            <p>MURAL 18 desenvolve-se de forma continuada como uma organiza&ccedil;&atilde;o verdadeiramente em rede que trabalha em conjunto para criar um reposit&oacute;rio dos diferentes dom&iacute;nios nas artes e cria&ccedil;&atilde;o art&iacute;stica e do patrim&oacute;nio arquitet&oacute;nico, urbano, arqueol&oacute;gico, m&oacute;vel e imaterial, nomeadamente:</p>
            <p>- Patrim&oacute;nio Arquitet&oacute;nico;<br />- Patrim&oacute;nio Natural;<br />- Patrim&oacute;nio Arqueol&oacute;gico;<br />- Patrim&oacute;nio Imaterial;<br />- Itiner&aacute;rios e Invent&aacute;rios Tem&aacute;ticos.</p>
            <p>Relativamente &agrave; &aacute;rea art&iacute;stica e cultural, a presente plataforma re&uacute;ne a seguinte informa&ccedil;&atilde;o:</p>
            <p>- Informa&ccedil;&atilde;o georreferenciada dos equipamentos culturais e patrimoniais existentes nos 18 munic&iacute;pios, de todo o patrim&oacute;nio cultural e natural existente;<br />- Agrega&ccedil;&atilde;o da oferta cultural (agendas culturais) nos 18 munic&iacute;pios da AML;<br />- Diret&oacute;rio de artistas locais e artistas emergentes, onde poder&atilde;o colocar as suas cria&ccedil;&otilde;es de forma a criarem sinergias e darem a conhecer o seu trabalho, possibilitando os munic&iacute;pios contact&aacute;-los para inserir na respetiva programa&ccedil;&atilde;o cultural;<br />- Bolsa de edif&iacute;cios municipais que estejam sem utiliza&ccedil;&atilde;o, podendo os artistas contactar o respetivo munic&iacute;pio para desenvolvimento de a&ccedil;&otilde;es culturais/art&iacute;sticas no munic&iacute;pio (programa&ccedil;&atilde;o cultural, resid&ecirc;ncias art&iacute;sticas, workshops, a&ccedil;&otilde;es de forma&ccedil;&atilde;o, &hellip;);<br />- Disponibiliza&ccedil;&atilde;o de conte&uacute;dos em streaming, da responsabilidade de cada munic&iacute;pio nas mais diversas vertentes art&iacute;sticas;<br />- Programa&ccedil;&atilde;o do MURAL 18.</p>
            <p>A AML estabelece como principais objetivos, inovar a infraestrutura de agrega&ccedil;&atilde;o, melhorar a usabilidade da plataforma, nomeadamente com a cria&ccedil;&atilde;o de canais tem&aacute;ticos, dar os primeiros passos para um novo modelo de agrega&ccedil;&atilde;o e desenvolver ferramentas criativas, que permitam novas utiliza&ccedil;&otilde;es para os conte&uacute;dos disponibilizados pelos parceiros (os munic&iacute;pios, estruturas culturais e art&iacute;sticas locais e parceiros institucionais).</p>
            <p>O presente projeto persegue o objetivo geral de fundir a arte, acultura, o patrim&oacute;nio, o turismo urbano e o digital atrav&eacute;s do desenvolvimento de uma Plataforma que recorra &agrave;s novas tecnologias e meios de comunica&ccedil;&atilde;o para ampliar o alcance da sua difus&atilde;o e promover a intera&ccedil;&atilde;o em rede dos e com os seus visitantes.</p>
            <p>- Despertar interesses e consci&ecirc;ncias junto do p&uacute;blico que habitualmente n&atilde;o visita entidades de &iacute;ndole cultural, com especial aten&ccedil;&atilde;o sobre o p&uacute;blico mais jovem;</p>
            <p>- Contribuir para a divulga&ccedil;&atilde;o do patrim&oacute;nio dos 18 munic&iacute;pios da AML;</p>
            <p>- Alertar a popula&ccedil;&atilde;o para a preserva&ccedil;&atilde;o e conserva&ccedil;&atilde;o do patrim&oacute;nio, incutindo uma maior consci&ecirc;ncia para a heran&ccedil;a cultural;</p>
            <p>- Validar a utiliza&ccedil;&atilde;o de produtos multim&eacute;dia em institui&ccedil;&otilde;es culturais, art&iacute;sticas, patrimoniais e museol&oacute;gicas;</p>
            <p>- Proporcionar elementos mediadores entre a cria&ccedil;&atilde;o art&iacute;stica, cultural e o p&uacute;blico, fruto da sociedade de informa&ccedil;&atilde;o;</p>
            <p>- Desenvolver projetos multim&eacute;dia de apoio cultural, art&iacute;stico e patrimonial dos 18 munic&iacute;pios da AML;</p>
            <p>- Provocar uma rela&ccedil;&atilde;o causa-efeito, um relacionamento ativo e interventivo;</p>
            <p>- Disponibilizar o acesso a v&aacute;rios conte&uacute;dos de teor educativo, art&iacute;stico, cultural e patrimonial;</p>
            <p>- Gerar efeitos multiplicadores em termos de reflex&atilde;o, projetos, financiamentos e interc&acirc;mbio.</p>
            <p>Estamos muralizados no apoio &agrave; cultura!</p>
            <p>&nbsp;</p>
        </>
    );
}

const infoEN = () => {
    return (
        <>
        <p><strong>Mural 18 Project</strong></p>
        <p>Mural 18 is a project developed by the Lisbon Metropolitan Area as part of the announcement of the Lisbon 2020 Regional Operational Program – Cultural Programming in Network.</p>
        <p>The main objective is to increase the attractiveness and dynamize the region's tourism development strategies and its economic dynamism, with a view to reducing asymmetries and strengthening territorial cohesion, through the holding of events associated with heritage, culture and goods cultural activities, developing network programming at the inter-municipal level.</p>
        <p>The Mural 18 project is developed in the following areas:</p>
        <p>- Cultural programming;<br />- 18 urban art murals;<br />- Reflection forum;<br />- Digital platform.</p>
        <p>Starting on January 15th, in all 18 municipalities, a cultural program is being developed in various artistic areas such as Music, Theatre, Cinema, Dance, Reading, Painting, Photography, Exhibitions, among others. </p>
        <p>This program will extend until the month of December.</p>
        <p>There are more than two hundred shows that will take place most of the time, in open air spaces, highlighting the vast natural and cultural heritage of the region.</p>
        <p>18 urban art murals will be created through interventions by 18 local artists between the months of March and August.</p>
        <p>Each municipality will nominate an artist, who will later be drawn to intervene in the territory in another municipality different from their origin.</p>
        <p>Each artist will be asked to make an intervention that conveys their vision of the fusion between culture, identity and metropolitan experience in the region.</p>
        <p>The Mural 18 project contemplates the holding of a Forum for reflection on issues related to culture.</p>
        <p>We will have two moments: </p>
        <p> the first one will be held in october, in the city of Setúbal.</p>
        <p>We will reflect on “Contemporary Culture and Society – what role for Local Administration?”.</p>
        <p>Through an institutional vision: Local Power versus Culture.</p>
        <p>Through a vision of cultural agents: Contributions to Local Intervention Strategies.</p>
        <p>A second moment takes place in December in the city of Lisbon.</p>
        <p>The motto will be “Cultural programming in network”. </p>
        <p>Here again the institutional vision: Network experiences – Balance and challenges. </p>
        <p>And the vision of cultural agents: One Culture, one identity and the metropolitan experience.</p>
        <p>The digital platform where we find ourselves is intended to be the meeting point for accessibility to culture and the arts in the territory of the Lisbon Metropolitan Area.</p>
        <p>MURAL 18 develops continuously as a truly networked organization that works together to create a repository in different domains in the arts and artistic creation and architectural, urban, archaeological, mobile and immaterial heritage, namely:</p>
        <p>- Architectural heritage; <br />- Natural heritage;<br />- Archaeological heritage;<br />- Intangible heritage;<br />- Itineraries and Thematic Inventories.</p>
        <p>Regarding the artistic and cultural area, this platform brings together the following information:</p>
        <p>- Geo-referenced information on cultural and heritage facilities in the 18 municipalities, on all existing cultural and natural heritage;<br />- Aggregation of the cultural offer (cultural agendas) in the 18 municipalities of the AML;<br />-Directory of local artists and emerging artists, where they can place their creations in order to create synergies and make their work known, enabling municipalities to contact them to include them in their cultural programme;<br />- Stock of unused municipal buildings, artists may contact the respective municipality for the development of cultural/artistic actions in the municipality (cultural programming, artistic residencies, workshops, training actions, …);<br />- Provision of streaming content, under the responsibility of each municipality in the most diverse artistic aspects;<br />- MURAL 18 program.</p>
        <p>AML's main objectives are to innovate the aggregation infrastructure, improve the usability of the platform, namely with the creation of thematic channels, take the first steps towards a new aggregation model and develop creative tools that allow new uses for the content made available by partners (municipalities, local cultural and artistic structures and institutional partners). </p>
        <p>This project pursues the general objective of merging art, culture, heritage, urban and digital tourism through the development of a platform that uses new technologies and means of communication to expand the reach of its dissemination and promote interaction in network of and with its visitors.</p>
        <p>- Awaken interests and consciences among the public that usually does not visit cultural entities, with special attention to the younger public;</p>
        <p>- Contribute to the dissemination of the heritage of the 18 municipalities of the AML;</p>
        <p>- Alert the population to the preservation and conservation of heritage, instilling greater awareness of cultural heritage;</p>
        <p>- Validate the use of multimedia products in cultural, artistic, heritage and museum institutions;</p>
        <p>- Provide mediating elements between artistic, cultural and public creation, as a result of the information society;</p>
        <p>- Develop multimedia projects for cultural, artistic and heritage support in the 18 municipalities of the AML;</p>
        <p>- Provoke a cause-effect relationship, an active and intervening relationship;</p>
        <p>- Provide access to various educational, artistic, cultural and heritage content;</p>
        <p>- Generate multiplying effects in terms of reflection, projects, financing and exchange.</p>
        <p>We are “muralized” in support of culture!</p>
        <p>&nbsp;</p>
    </>
        );
}