import React, { useState } from 'react';
import { slide as Menu } from 'react-burger-menu'
import { FiMenu } from 'react-icons/fi';
import { IoMdArrowDropright } from 'react-icons/io';
import InstallPWA from '../InstallPWA';
import { menus, images, languagePosition_forArray } from '../Utils';

import './NavMenu.css'

export default function NavMenu() {

  const [NavMenuOpen, setNavMenuOpen] = useState(false);
  const currentMenu = window.location.pathname;

  const clearLocal = () => {
    let lang = localStorage.getItem('LanguageID');
    localStorage.clear();
    localStorage.setItem('LanguageID', lang);
  }

  return (<>
    <FiMenu size={35} style={{ cursor: 'pointer', }} onClick={() => setNavMenuOpen(true)} />
    <Menu id={ "NavMenu" } isOpen={NavMenuOpen} width={340} onClose={() => setNavMenuOpen(false)}>
      <div className="menu-title">
        <div style={{display: 'flex', alignItems: 'center'}}>
          <img onClick={() => setNavMenuOpen(false)} style={{height: '26px', width: '27px', cursor:'pointer'}} src={images('./icone_back_amarelo.png')} alt="Fechar"/>
          <img style={{paddingLeft:'15px'}}  src={images('./logo.png')} alt="Logo"/>
          <label></label>
        </div>
      </div>
      {menus.filter((m) => m.activeState === true).map((menu) => (
        <a key={menu.id} className={'menu-item '+ (menu.id === 12 ? 'archived ' : '') + (currentMenu.includes(menu.url) ? 'active' : '')} href={menu.url} onClick={clearLocal}>
          <img src={images('./' + menu.imgNavMenu + '.png')} alt={menu.name[languagePosition_forArray]} />
          <span>{menu.name[languagePosition_forArray]}</span>
          <IoMdArrowDropright className='menu-arrow' />
        </a>
      ))}
      <InstallPWA />
    </Menu>
  </>
  );
}
