import React, { useState, useEffect } from 'react';
import { Swipeable } from "react-swipeable";
import './SliderWithoutInfo.css';
import { time, images, appLink } from '../Utils';
import { useHistory } from 'react-router-dom';
import ICalendarLink from "react-icalendar-link";
import { Dropdown } from 'react-bootstrap';
import { isMobile } from "react-device-detect";
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    EmailShareButton,

    // Comment to sepaate, overwriting codesandbox behavior
    FacebookIcon,
    TwitterIcon,
    LinkedinIcon,
    WhatsappIcon,
    EmailIcon
} from "react-share";

export default function SliderWithoutInfo(props) {
    const history = useHistory();
    const dataObject = props.info;
    const event = {
        title: props.info.name,
        description: props.info.synopsis,
        location: props.info.contentLocalAddress,
        startTime: props.info.startDateAux,
        endTime: props.info.endDateAux,
    };
    const IMG1 = images('./background-banner_black.jpg');
    const [active, setActive] = useState(0);
    const [LinkStage, setLinkStage] = useState(0)

    useEffect(() => {
        var timer = null;
        if (dataObject.hasVideo) {
            if (dataObject.isStreaming) {
                if (Date.now() > new Date(dataObject.startDateAux).getTime()) {
                    setLinkStage(3);
                } else {
                    setLinkStage(2);
                    var time = new Date(dataObject.startDateAux).getTime() - Date.now();
                    if (time < 86400) {
                        timer = setTimeout(() => { setLinkStage(3) }, time);
                    }
                }
            } else {
                if (dataObject.linkVideo !== "" && dataObject.linkVideo != null) {
                    setLinkStage(4);
                }
            }
        }

        return () => {
            clearTimeout(timer);
        }
    }, [dataObject]);



    useEffect(() => {
        const next = (active + 1) % dataObject.presentationImages.length;
        const id = setTimeout(() => setActive(next), time);
        return () => clearTimeout(id);
    }, [active, dataObject.presentationImages.length]);

    const onSwiped = (side) => {
        if (side === 'RIGHT') {
            setActive((active + 1) % dataObject.presentationImages.length);
        } else {
            if (active === 0) {
                setActive(dataObject.presentationImages.length - 1);
            } else {
                setActive(active - 1);
            }
        }
    }

    const changeActiveSlide = (position) => {
        setActive(position);
    }

    const handleVideoClick = () => {
        //if (dataObject.vimeoId != null) {
        localStorage.setItem('item', JSON.stringify(props.responseData));
        history.push({ pathname: '/video', state: props.info });
        //}
    }

    return (
        <div id="montra-destaque-banner-inner" style={{ backgroundImage: "url(" + IMG1 + ")", backgroundRepeat: "no-repeat", backgroundPosition: 'center', backgroundSize: 'cover' }}>
            {dataObject.presentationImages.map((image, i) => (
                <div key={image.id} className={active === i ? "montra-destaque-home active" : "montra-destaque-home"} >
                    <div className="montra-destaque-img-container container">
                        <div className="montra-destaque-img" style={{ background: `url(${image.link + '&r_AR=o&r_W=994'})`, backgroundRepeat: "no-repeat", backgroundPosition: 'center', backgroundSize: 'cover' }}></div>
                    </div>
                </div>
            ))}
            {dataObject.presentationImages.length === 0 ?
                <div className={"montra-destaque-home active"}>
                    <div className="montra-destaque-img" style={{ background: '#ccc', backgroundRepeat: "no-repeat", backgroundPosition: 'center', backgroundSize: 'cover' }}></div>
                </div>
                : <></>
            }
            <Swipeable
                onSwipedLeft={() => onSwiped('RIGHT')}
                onSwipedRight={() => onSwiped('LEFT')}
                className="montra-previews"
            >
                <div className="container">
                    <div id="montra-previews-minis">
                        {dataObject.presentationImages.map((image, i) => (
                            <div key={image.id} className={active === i ? "montra-destaque-mini active" : "montra-destaque-mini"} onClick={() => changeActiveSlide(i)}>
                            </div>
                        ))}
                    </div>
                    <div className="montra-destaque-buttons" style={{ justifyContent: 'flex-end' }}>
                        {dataObject.contentTypeID === 1 ? <>
                            {LinkStage === 2 ? <img src={images('./icone_stream_amarelo.png')} style={{ cursor: 'initial' }} className="shadow-white" alt="video" /> : <></>}
                            {LinkStage === 3 ? <img src={images('./icone_stream_red1.png')} className="shadow-white" alt="video" onClick={handleVideoClick} /> : <></>}
                            {LinkStage === 4 ? <img src={images('./icone_stream_amarelo_streaming.png')} className="shadow-white" alt="video" onClick={handleVideoClick} /> : <></>}
                        </> : <> </>}
                        <Share type={2} props={dataObject} />
                        {dataObject.contentTypeID === 1 ?
                            <ICalendarLink event={event}>
                                <img src={images('./icone_calendar_amarelo.png')} className="shadow-white" alt="calendario" />
                            </ICalendarLink>
                            : <> </>}
                    </div>
                </div>
            </Swipeable>
            <div className="montra-destaque-content">
                <div className="container" style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '10px' }}>
                    <div>
                        <label className="montra-destaque-title" style={{ width: '100%', margin: '0' }}>{dataObject.name}</label>
                        <label style={{ margin: '0', color: '#676767' }}>{dataObject.contyName}</label>
                    </div>
                    <div className="montra-destaque-buttons-ontitle" style={{ marginTop: '9px', justifyContent: 'flex-end' }}>
                        {dataObject.contentTypeID === 1 ? <>
                            {LinkStage === 2 ? <img src={images('./icone_stream.png')} style={{ cursor: 'initial' }} className="shadow-white" alt="video" /> : <></>}
                            {LinkStage === 3 ? <img src={images('./icone_stream_red2.png')} className="shadow-white" alt="video" onClick={handleVideoClick} /> : <></>}
                            {LinkStage === 4 ? <img src={images('./icone_stream_streaming.png')} className="shadow-white" alt="video" onClick={handleVideoClick} /> : <></>}
                        </> : <> </>}
                        <Share type={1} props={dataObject} />
                        {dataObject.contentTypeID === 1 ?
                            <ICalendarLink event={event}>
                                <img src={images('./icone_calendar.png')} className="shadow-white" alt="calendario" />
                            </ICalendarLink>
                            : <> </>}
                    </div>
                </div>
            </div>
        </div>

    )
}

const Share = (props) => {

    const link = appLink + "details/" + props.props.id
    const [mobile, setMobile] = useState(isMobile);

    const handleOnClick = () => {
        if (navigator.share) {
            navigator
                .share({
                    title: "Mural 18",
                    text: props.props.name,
                    url: link,
                })
                .then(() => {
                    console.log('Successfully shared');
                })
                .catch(error => {
                    console.error('Something went wrong sharing the blog', error);
                });
        } else {
            setMobile(false);
        }
    };

    return (
        <>
            {mobile === true ?
                props.type === 2 ? <img src={images('./icone_share_amarelo.png')} className="shadow-white" alt="partilha" style={{ marginLeft: '15px', marginRight: '15px' }} onClick={handleOnClick} />
                    : <img src={images('./icone_share.png')} alt="" className="shadow-white" style={{ marginLeft: '15px', marginRight: '15px' }} onClick={handleOnClick} />
                :
                <Dropdown>
                    <Dropdown.Toggle className="dropdown-toggle" id="dropdown-basic">
                        {props.type === 2 ? <img src={images('./icone_share_amarelo.png')} className="shadow-white" alt="partilha" style={{ marginLeft: '15px', marginRight: '15px' }} />
                            : <img src={images('./icone_share.png')} className="shadow-white" alt="" style={{ marginLeft: '15px', marginRight: '15px' }} />
                        }
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <FacebookShareButton
                            url={link}
                            quote={props.props.name}>
                            <FacebookIcon size={36} />
                        </FacebookShareButton>
                        <LinkedinShareButton
                            url={link}
                            title={props.props.name}
                            summary={props.props.synopsis}
                            source="Mural 18">
                            <LinkedinIcon size={36} />
                        </LinkedinShareButton>
                        <TwitterShareButton
                            url={link}
                            title={props.props.name}>
                            <TwitterIcon size={36} />
                        </TwitterShareButton>
                        <WhatsappShareButton
                            url={link}
                            title={props.props.name}>
                            <WhatsappIcon size={36} />
                        </WhatsappShareButton>
                        <EmailShareButton
                            url={link}
                            subject={props.props.name}
                            body={props.props.synopsis}>
                            <EmailIcon size={36} />
                        </EmailShareButton>
                    </Dropdown.Menu>
                </Dropdown>
            }
        </>
    )
}
