import React, { useEffect, useState } from 'react'
import { images, languagePosition_forArray } from './Utils'
//import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import 'antd/dist/antd.css';
import locale from 'antd/es/date-picker/locale/pt_PT';
import { DatePicker, Space } from 'antd';
import moment from 'moment';
import 'moment/locale/pt';
import {TiDelete} from 'react-icons/ti';
const { RangePicker } = DatePicker;

const Filters = (props) => {
    return (
        <div className="row" style={{ marginBottom: '5px' }}>
            {/*Montra artes*/
                props.menuID === 1 || props.menuID === 12 ?
                    <>
                        <FiltroSearch props={props} isTab={true} />
                        <FiltroCategoria props={props} isTab={true} />
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 montra-filter-more-div">
                            <div className="row">
                                <FiltroCounty props={props} />
                                <FiltroDate props={props} />
                                <FiltroOrder props={props} />
                            </div>
                        </div>
                    </> : <></>
            }
            {/*patrimonio, museus e artePublica */
                props.menuID === 2 || props.menuID === 3 || props.menuID === 8 ?
                    <>
                        <FiltroSearch props={props} isTab={true} />
                        <FiltroCategoria props={props} isTab={true} />
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 montra-filter-more-div">
                            <div className="row">
                                <FiltroCounty props={props} />
                                <FiltroOrder props={props} />
                            </div>
                        </div>
                    </> : <></>
            }
            {/*Equipamentos Culturais, Bolsa de Edifícios*/
                props.menuID === 4 || props.menuID === 5 ?
                    <>
                        <FiltroSearch props={props} isTab={true} />
                        <FiltroCounty props={props} isTab={true} />
                        <FiltroOrder props={props} isTab={true} />

                    </> : <></>
            }
            {/*artista */
                props.menuID === 6 ?
                    <>
                        <FiltroCategoria props={props} isTab={true} />
                        <FiltroSearch props={props} isTab={true} />
                        <FiltroOrder props={props} isTab={true} />

                    </> : <></>
            }

        </div >
    )
}

const FiltroSearch = (filtro) => {
    const seachrLabel = ["Pesquisar...", "Search..."];
    let typingTimer = null;
    const [inputVal, setInputValue] = useState(filtro.props.filter_search);
    const handleChange = (val, clear) => {
        if(clear){
            setInputValue("");
        }else{
            setInputValue(val);
        }
        clearTimeout(typingTimer);
        typingTimer = setTimeout(() => {
            filtro.props.setSearch(val)
        }, 1000);
    }

    useEffect(() => {
        return () => {
            clearTimeout(typingTimer);
        }
    }, [typingTimer])

    return (
        <div className={filtro.isTab ? "col-sm montra-filter" : "col-sm montra-filter-more"}>
            <div style={{ paddingRight: "25px" }}>
                <img src={images('./filtros_pesquisa.png')} alt="Pesquisa" />
            </div>
            <input value={inputVal} placeholder={seachrLabel[languagePosition_forArray]} onChange={e => handleChange(e.target.value, false)} style={{ border: 'none', backgroundColor: '#000', width: '100%' }} />
            {inputVal.length > 0 ? <div style={{ paddingLeft: "5px" }}>
                <TiDelete color={"#a3a3a3"} size={20} onClick={() => handleChange("", true)} />
            </div>:<></>}
        </div>
    )
}

const FiltroCategoria = (filtro) => {
    const category = ["Categoria..." , "Category..."];

    return (
        <div className={filtro.isTab ? "col-sm montra-filter" : "col-sm montra-filter-more"}>
            <div style={{ paddingRight: "25px" }}>
                <img src={images('./filtros_categorias.png')} alt="ordem" />
            </div>
            <select id="category" value={filtro.props.filter_category} onChange={e => filtro.props.setCategory(e.target.value)}>
                <option value={0}>{category[languagePosition_forArray]}</option>
                {filtro.props.category !== null ?
                    filtro.props.category.map(category => (<option key={category.id} value={category.id}>{category.name}</option>)) : <></>
                }
            </select>
        </div>
    )
}

const FiltroOrder = (filtro) => {
    const ascendDate = ["Ascendente (Data)" , "Ascending (Date)"];
    const desascendDate = ["Descendente (Data)" , "Descendant (Date)"];
    const ascendAZ = ["Ascendente (A-Z)" , "Ascending (A-Z)"];
    const desascendAZ = ["Descendente (Z-A)" , "Descendant (Z-A)" ];

    return (
        <div className={filtro.isTab ? "col-sm montra-filter" : "col-sm montra-filter-more"}>
            <div style={{ paddingRight: "25px" }}>
                <img src={images('./filtros_sort.png')} alt="ordem" />
            </div>
            <select id="orderBy" value={filtro.props.filterOrder} onChange={e => filtro.props.setFilterOrder(e.target.value, e.target.childNodes[e.target.selectedIndex].getAttribute('data-column'), e.target.childNodes[e.target.selectedIndex].getAttribute('data-desc'))}>
                <option value="1" data-column={filtro.props.filterOrderColumn} data-desc="false">{filtro.props.menuID === 1 || filtro.props.menuID === 12 ? ascendDate[languagePosition_forArray] : ascendAZ[languagePosition_forArray]}</option>
                <option value="2" data-column={filtro.props.filterOrderColumn} data-desc="true">{filtro.props.menuID === 1 || filtro.props.menuID === 12 ? desascendDate[languagePosition_forArray] : desascendAZ[languagePosition_forArray]}</option>
            </select>
        </div>
    )
}

const FiltroDate = (filtro) => {

    const startDateLabel = ["Data Inicial", "Start Date"];
    const endDateLabel = ["Data Final", "End Date"];

    const disabled = (current) => {
        if (current < moment().add(-1, 'days') && !filtro.props.archived) {
            return true;
        }
        else if (current > moment().add(-1, 'days') && filtro.props.archived) {
            return true;
        }
        else {
            return false;
        }
    }

    return (
        <div className="col-sm montra-filter-more" style={{ paddingBottom: "7px", paddingTop: "7px" }}>
            <div>
                <img src={images('./filtros_calendario.png')} alt="calendario" />
            </div>
            <Space direction="vertical" size={20}>
                <RangePicker locale={locale} format={"DD/MM/YYYY"} onChange={filtro.props.setDate} allowClear={true} suffixIcon={null}
                    defaultValue={filtro.props.date !== null ? [moment(filtro.props.date[0]), moment(filtro.props.date[1])] : ""}
                    disabledDate={(val) => disabled(val)} placeholder={[startDateLabel[languagePosition_forArray], endDateLabel[languagePosition_forArray]]}
                />
            </Space>
        </div>
    )
}

const FiltroCounty = (filtro) => {
    const countyLabel = ["Município...", "County..."]

    return (
        <div className={filtro.isTab ? "col-sm montra-filter" : "col-sm montra-filter-more"}>
            <div style={{ paddingRight: "25px" }}>
                <img src={images('./filtros_concelho.png')} alt="concelho" />
            </div>
            <select id="county" value={filtro.props.filter_county} onChange={e => filtro.props.setCounty(e.target.value)}>
                <option value={0}>{countyLabel[languagePosition_forArray]}</option>
                {filtro.props.county !== null ?
                    filtro.props.county.map(county => (
                        <option key={county.id} value={county.id}>{county.name}</option>
                    )) : <></>
                }
            </select>
        </div>
    )
}

export default Filters;