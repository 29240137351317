import api from "../services/api";

export const menus = [
    { id: 7, name: ["Início","Home"], imgNavMenu: "menu_inicio", img: "", url: '/home', showOnMenu: false, activeState: true },
    { id: 1, name: ["Montra de Artes e Cultura","Showcase of Arts and Culture"], imgNavMenu: "menu_montra-artes-cultura", img: "icone_montra-artes-cultura", url: '/showcase', showOnMenu: true, activeState: true },
    { id: 2, name: ["Património","Patrimony" ], imgNavMenu: "menu_patrimonio", img: "icone_patrimonio", url: '/patrimony', showOnMenu: true, activeState: true },
    { id: 3, name: ["Museus", "Museums" ], imgNavMenu: "menu_museus", img: "icone_museus", url: '/museums', showOnMenu: true, activeState: true },
    { id: 4, name: ["Equipamentos Culturais", "Cultural Equipment" ], imgNavMenu: "menu_equipamentos-culturais", img: "icone_equipamentos-culturais", url: '/culturaleEuipment', showOnMenu: true, activeState: true },
    { id: 8, name: ["Arte Pública", "Public Art" ], imgNavMenu: "menu_arte-publica", img: "icone_arte-publica", url: '/publicArt', showOnMenu: true, activeState: true },
    { id: 9, name: ["Agenda","Schedule" ], imgNavMenu: "menu_agenda", img: "icone_agenda", url: '/schedule', showOnMenu: true, activeState: true },
    { id: 6, name: ["Directório de Artistas", "Artists Directory" ], imgNavMenu: "menu_artistas", img: "icone_artistas", url: '/artists', showOnMenu: true, activeState: true },
    { id: 5, name: ["Bolsa de Edifícios","Buildings Exchange" ], imgNavMenu: "menu_edificios", img: "icone_edificios", url: '/buildings', showOnMenu: true, activeState: true },
    { id: 10, name: ["Mapa","Map" ], imgNavMenu: "menu_mapa", img: "", url: '/mapa', showOnMenu: false, activeState: false },
    { id: 10, name: ["Mapa","Map" ], imgNavMenu: "menu_mapa", img: "", url: '/maps', showOnMenu: false, activeState: false },
    { id: 12, name: ["Arquivo","Archive" ], imgNavMenu: "menu_arquivo", img: "", url: '/archive', showOnMenu: false, activeState: true },
    { id: 11, name: ["Contactos","Contacts" ], imgNavMenu: "menu_contactos", img: "", url: '/contacts', showOnMenu: false, activeState: true },
];

/*export const cards = [
    { id: 1, imgs: [{id: 2, img:'image-2' },{id: 11, img:'image-11' },{id: 12, img:'image-12' },{id: 13, img:'image-13' },],destaque:true, title: 'blalballabl asdfasd adsfasd fgdh dfghdf ha dfasdf', date: '19 Setembro', local: 'Rua consectetur, Lisboa', sinopse: "Sinopse 1 Sinopse 1 Sinopse 1 Sinopse 1 Sinopse 1 Sinopse 1 Sinopse 1 Sinopse 1 Sinopse 1 Sinopse 1 Sinopse 1 Sinopse 1 Sinopse 1" },
    { id: 2, imgs: [{id: 3, img:'image-3' },{id: 14, img:'image-14' },{id: 15, img:'image-15' },{id: 16, img:'image-16' },],destaque:false, title: 'blalballabl asdf', date: '20 Setembro', local: 'Rua consectetur, Lisboa', sinopse: "Sinopse 2 Sinopse 2 Sinopse 2 Sinopse 2 Sinopse 2 Sinopse 2 Sinopse 2 Sinopse 2 Sinopse 2 Sinopse 2 Sinopse 2 Sinopse 2 Sinopse 2" },
    { id: 3, imgs: [{id: 4, img:'image-4' },{id: 17, img:'image-17' },{id: 18, img:'image-18' },{id: 19, img:'image-19' },],destaque:true, title: 'blalballabl asdf', date: '26 Setembro', local: 'Rua consectetur, Lisboa', sinopse: "Sinopse 3 Sinopse 3 Sinopse 3 Sinopse 3 Sinopse 3 Sinopse 3 Sinopse 3 Sinopse 3 Sinopse 3 Sinopse 3 Sinopse 3 Sinopse 3 Sinopse 3" },
    { id: 4, imgs: [{id: 5, img:'image-5' },{id: 11, img:'image-11' },{id: 12, img:'image-12' },{id: 13, img:'image-13' },],destaque:false, title: 'blalballabl asdf', date: '19 Setembro', local: 'Rua consectetur, Lisboa', sinopse: "Sinopse 4 Sinopse 4 Sinopse 4 Sinopse 4 Sinopse 4 Sinopse 4 Sinopse 4 Sinopse 4 Sinopse 4 Sinopse 4 Sinopse 4 Sinopse 4 Sinopse 4 " },
    { id: 5, imgs: [{id: 6, img:'image-6' },{id: 14, img:'image-14' },{id: 15, img:'image-15' },{id: 16, img:'image-16' },],destaque:true, title: 'blalballabl asdf', date: '19 Setembro', local: 'Rua consectetur, Lisboa', sinopse: "Sinopse 5 Sinopse 5 Sinopse 5 Sinopse 5 Sinopse 5 Sinopse 5 Sinopse 5 Sinopse 5 Sinopse 5 Sinopse 5 Sinopse 5 Sinopse 5 Sinopse 5 " },
    { id: 6, imgs: [{id: 7, img:'image-7' },{id: 17, img:'image-17' },{id: 18, img:'image-18' },{id: 19, img:'image-19' },],destaque:false, title: 'blalballabl asdf', date: '19 Setembro', local: 'Rua consectetur, Lisboa', sinopse: "Sinopse 6 Sinopse 6 Sinopse 6 Sinopse 6 Sinopse 6 Sinopse 6 Sinopse 6 Sinopse 6 Sinopse 6 Sinopse 6 Sinopse 6 Sinopse 6 Sinopse 6 " },
    { id: 7, imgs: [{id: 8, img:'image-8' },{id: 11, img:'image-11' },{id: 12, img:'image-12' },{id: 13, img:'image-13' },],destaque:true, title: 'blalballabl asdf', date: '19 Setembro', local: 'Rua consectetur, Lisboa', sinopse: "Sinopse 7 Sinopse 7 Sinopse 7 Sinopse 7 Sinopse 7 Sinopse 7 Sinopse 7 Sinopse 7 Sinopse 7 Sinopse 7 Sinopse 7 Sinopse 7 Sinopse 7 " },
    { id: 8, imgs: [{id: 9, img:'image-9' },{id: 14, img:'image-14' },{id: 15, img:'image-15' },{id: 16, img:'image-16' },],destaque:false, title: 'blalballabl asdf', date: '19 Setembro', local: 'Rua consectetur, Lisboa', sinopse: "Sinopse 8 Sinopse 8 Sinopse 8 Sinopse 8 Sinopse 8 Sinopse 8 Sinopse 8 Sinopse 8 Sinopse 8 Sinopse 8 Sinopse 8 Sinopse 8 Sinopse 8 " },
    { id: 9, imgs: [{id: 10, img:'image-10' },{id: 17, img:'image-17' },{id: 18, img:'image-18' },{id: 19, img:'image-19' },],destaque:false, title: 'blalballabl asdf', date: '19 Setembro', local: 'Rua consectetur, Lisboa', sinopse: "Sinopse 9 Sinopse 9 Sinopse 9 Sinopse 9 Sinopse 9 Sinopse 9 Sinopse 9 Sinopse 9 Sinopse 9 Sinopse 9 Sinopse 9 Sinopse 9 Sinopse 9 " },

];*/

/*export const ScheduleCards = [
    { id: 1, presentationImages : [{d: 1, img:'Lisboa'}], name: 'Lisboa', date: '', local: '', url: 'https://www.lisboa.pt/agenda' },
    { id: 2, presentationImages : [{d: 2, img:'Almada'}], name: 'Almada', date: '', local: '', url: 'http://www.m-almada.pt/xportal/xmain?xpid=cmav2&xpgid=agenda' },
    { id: 3, presentationImages : [{d: 3, img:'Sintra'}], name: 'Sintra', date: '', local: '', url: 'https://cm-sintra.pt/atualidade/agenda' },
];*/

export const LanguageID = () => {
    let languageID = localStorage.getItem('LanguageID');
    if(isNaN(parseInt(languageID))){
        localStorage.setItem('LanguageID', 1);
        return 1;
    }
        else
        return parseInt(languageID);
}

export const languagePosition_forArray = LanguageID() - 1;

//export const appLink = "https://amlmural-demo.noshape.com/";
export const appLink = "https://www.mural18.pt/";

export async function GetHighlightMini_ForFront(contentTypeID) {
    try {
        /*if (localStorage.getItem('DateExpire') !== null) {
            let dateExpire = localStorage.getItem('DateExpire');
            if (dateExpire > new Date().getTime()) {
                if (localStorage.getItem('Highlights') !== null)
                    return JSON.parse(localStorage.getItem('Highlights'));
            } else {
                localStorage.clear();
            }
        }*/
        var languageID = LanguageID();
        const response = await api.post('GetHighlightMini_ForFront',{contentTypeID: contentTypeID, languageID: languageID});
        //localStorage.clear();
        //let date = new Date().setDate(new Date().getDate());
        //localStorage.setItem('DateExpire', (date.setHours(date.getHours() + 1)));
        //localStorage.setItem('Highlights', JSON.stringify(response));
        return response;

    } catch (err) {
        return err;
    }
}

export async function GetHighlightMini_ForFront_Paginate(resultsAfterID) {
    try {
        var languageID = LanguageID();
        const response = await api.post('GetHighlightMini_ForFront_Paginate', { resultsAfterID: resultsAfterID, languageID: languageID });
        return response;
    } catch (err) {
        return err;
    }
}

export async function GetMini_ForSchedule() {
    try {
        var languageID = LanguageID();
        const response = await api.post('GetMini_ForSchedule', { languageID: languageID });
        return response;
    } catch (err) {
        return err;
    }
}

export async function GetMini_ForFront_Paginate(contentTypeID,resultsAfterID, sortFieldName, sortDesc, filterCategoryID, filterCountyID, filterStartDate, filterEndDate,filter_search, ignoreCache) {
    try {
        if(ignoreCache === false){
            if (localStorage.getItem('DateExpire') !== null) {
                let dateExpire = localStorage.getItem('DateExpire');
                if (dateExpire > new Date().getTime()) {
                            if (localStorage.getItem('Content') !== null)
                                return JSON.parse(localStorage.getItem('Content'));
                } else {
                    localStorage.clear();
                }
            }
        }
        var languageID = LanguageID();
        const response = await api.post('GetMini_ForFront_Paginate',
        {
            contentTypeID: contentTypeID,
            resultsAfterID: resultsAfterID, 
            sortFieldName:sortFieldName,
            sortDesc: sortDesc,
            filterCategoryID: filterCategoryID,
            filterCountyID: filterCountyID,
            filterStartDate: filterStartDate,
            filterEndDate: filterEndDate,
            searchName: filter_search,
            languageID: languageID
        });
        if (response.status === 200){
            return response.data.GetMini_ForFront_PaginateResult;
        }else {
            return "erro";
        }
    } catch (err) {
        return err;
    }
}

export async function ContentInfoFO_GetByID(id) {
    try {
        /*if (localStorage.getItem('DateExpire') !== null) {
            let dateExpire = localStorage.getItem('DateExpire');
            if (dateExpire > new Date().getTime()) {
                if (localStorage.getItem('item') !== null)
                    return JSON.parse(localStorage.getItem('item'));
            } else {
                localStorage.clear();
            }
        }*/
        var languageID = LanguageID();
        const response = await api.post('ContentInfoFO_GetByID', { id: id , languageID: languageID});
        return response;
    } catch (err) {
        return err;
    }
}

/*export async function GetVimeo(id) {
    try {
        const response = await api.post('GetVimeoVideo', { vimeoID: id })
        return response;
    } catch (err) {
        return err;
    }
}
*/
export async function CountyInfo_GetAll() {
    try {
        if (localStorage.getItem('DateExpire') !== null) {
            let dateExpire = localStorage.getItem('DateExpire');
            if (dateExpire > new Date().getTime()) {
                if (localStorage.getItem('County') !== null)
                    return JSON.parse(localStorage.getItem('County'));
            } else {
                localStorage.clear();
            }
        }

        const response = await api.get('CountyInfo_GetAll');
        return response;
    } catch (err) {
        return err;
    }
}

export async function ContentCategoryInfoFO_GetAll(contentTypeID) {
    try {
        /*if (localStorage.getItem('DateExpire') !== null) {
            let dateExpire = localStorage.getItem('DateExpire');
            if (dateExpire > new Date().getTime()) {
                if (localStorage.getItem('ContentCategory') !== null)
                        return JSON.parse(localStorage.getItem('ContentCategory'));
            } else {
                localStorage.clear();
            }
        }*/
        var languageID = LanguageID();
        const response = await api.post('ContentCategoryInfoFO_GetAll',{contentTypeID: contentTypeID, languageID: languageID});
        //localStorage.setItem('ContentCategory', JSON.stringify(response));
        return response;
    } catch (err) {
        return err;
    }
}

export async function FO_MapsInfo_GetAll() {
    try {
        /*if (localStorage.getItem('DateExpire') !== null) {
            let dateExpire = localStorage.getItem('DateExpire');
            if (dateExpire > new Date().getTime()) {
                if (localStorage.getItem('ContentCategory') !== null)
                        return JSON.parse(localStorage.getItem('ContentCategory'));
            } else {
                localStorage.clear();
            }
        }*/
        var languageID = LanguageID();
        const response = await api.post('FO_MapsInfo_GetAll',{languageID: languageID});
        //localStorage.setItem('ContentCategory', JSON.stringify(response));
        return response;
    } catch (err) {
        return err;
    }
}

export function GetUserLocation() {
    return navigator.geolocation.getCurrentPosition(function (position) {
        return { GPS_Lat: position.coords.latitude, GPS_Lng: position.coords.longitude };
    });
}

export const time = 5000; //slider time
export const images = require.context('../assets', true);

export const regexCoord = new RegExp(/^-?[0-9]{1,3}(?:\.[0-9]{1,20})?$/);

