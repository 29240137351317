import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import Footer from '../Footer';
import Header from '../Header';
import SliderWithoutInfo from '../Slider/SliderWithoutInfo';
import { ContentInfoFO_GetByID, images, languagePosition_forArray, regexCoord } from '../Utils';
import Loader from '../Loader';
import { useHistory } from 'react-router-dom';
import Parser from 'html-react-parser'

export default function Montra_Details(props) {


    const history = useHistory();
    const [data, setData] = useState();
    const [error, setError] = useState();
    const [response, setResponse] = useState();
    const labelMap = ['Mapa', 'Map'];

    useEffect(() => {
        const errorEvent = ["Não existe informação sobre este evento.", "There is no information on this event."];
        const errorPage = ["Não foi possivel abrir a pagina em questão.", "It was not possible to open the page in question."];
        const id = parseInt(props.location.pathname.substring(props.location.pathname.lastIndexOf('/') + 1));
        if (id > 0) {
            ContentInfoFO_GetByID(id).then(result => {
                if (result.status === 200) {
                    if (result.data.ContentInfoFO_GetByIDResult !== null) {
                        setData(result.data.ContentInfoFO_GetByIDResult)
                        setResponse(result);
                        localStorage.removeItem("item");
                    } else {
                        setError(errorEvent[languagePosition_forArray])
                    }
                }
            })
        } else {
            alert(errorPage[languagePosition_forArray]);
            history.push({ pathname: '/home' });
        }
    }, [props.location, history]);



    return (
        <>{data !== undefined ?
            <>
                <Header hasTitle={true} title={data.contentCategoryName !== "" && data.contentCategoryName !== null ? data.contentCategoryName : ""}
                    showSideBar={true} GoBackOnTitle={'/showcase'} MapButton={
                        regexCoord.test(data.GPS_Lat) && regexCoord.test(data.GPS_Lng) ?
                            <Link to={{ pathname: '/mapa', state: data }} style={{ marginLeft: "10px" }}>
                                <img src={images('./icone_mapa_amarelo.png')} alt="mapa" />
                            </Link>
                            : <></>
                    }
                    MapNavButton={regexCoord.test(data.GPS_Lat) && regexCoord.test(data.GPS_Lng) ?
                        <Link className={'menu-item'} to={{ pathname: '/mapa', state: data }}>
                            <img src={images('./icone_mapa_amarelo.png')} alt="information" />
                            <span>{labelMap[languagePosition_forArray]}</span>
                        </Link>
                        : <></>
                    }
                />
                <div className="main">
                    <SliderWithoutInfo info={data} responseData={response} />

                    <div className="container" style={{ marginTop: '20px', marginBottom: '20px' }}>
                        <MontraDetailsGeral info={data} />
                        <MontraDetailsAdditionalInformation info={data} />
                        <MontraDetailsMultimediaContent info={data} />
                    </div>
                </div>
                <Footer />
            </>
            :
            <>
                <Header hasTitle={true} title={""} showSideBar={true} GoBackOnTitle={'/showcase'} />
                <div className="main">
                    {error !== undefined ?
                        <div style={{ textAlign: "center", padding: "30px 0px" }}>
                            <img src={images('./icone_sem-resultados.png')} alt="Não existem resultados disponíveis" />
                            <label style={{ color: "#a3a3a3", fontSize: "18px", width: "100%", paddingTop: "15px" }}>{error}</label>
                        </div>
                        : <Loader />}
                </div>
                <Footer />
            </>

        }
        </>
    );
}

export const MontraDetailsGeral = props => {

    const labelTitle = ["Descrição geral", "General description"];
    const scheduleLabel = ["Horário", "Schedule"];
    const synopsisLabel = ["Sinopse", "Synopsis"];
    const locationLabel = ["Local", "Location"];
    const mapLabel = ["Ver Mapa", "See Map"];
    const contactsLabel = ["Contactos", "Contacts"];
    const startDateLabel = ["Data de ínicio", "Start Date"];
    const endDateLabel = ["Data de fim", "End Date"];
    const availableAreaLabel = ["Área disponível", "Available area"];
    const capacityLabel = ["Capacidade", "Capacity"];
    const ticketOfficeLabel = ["Bilheteira", "Ticket Office"];

    const [isOpen, setIsOpen] = useState(true);

    return (
        <>
            <div className={isOpen ? "montra-info-header montra-info-header-active" : "montra-info-header"} onClick={() => setIsOpen(!isOpen)}>
                <label>{labelTitle[languagePosition_forArray]}</label>
                {
                    isOpen ? <IoIosArrowUp size={25} /> : <IoIosArrowDown size={25} />
                }
            </div>

            <div className="montra-info-content" style={{ display: isOpen ? "" : "none" }}>
                {props.info.schedule !== null && props.info.schedule !== "" ?
                    <div className="montra-info-content-item">
                        <div style={{ marginRight: '10px' }}>
                            <img src={images('./icone_descrição_horario.png')} alt="" />
                        </div>
                        <div>
                            <label className="montra-info-content-title">{scheduleLabel[languagePosition_forArray]}</label>
                            <label className="montra-info-content-description">{props.info.schedule}</label>
                        </div>
                    </div>
                    : <></>
                }
                {props.info.contentLocalAddress !== null && props.info.contentLocalAddress !== "" ?
                    <div className="montra-info-content-item">
                        <div style={{ marginRight: '10px' }}>
                            <img src={images('./icone_descrição_local.png')} alt="" />
                        </div>
                        <div>
                            <label className="montra-info-content-title">{locationLabel[languagePosition_forArray]}</label>
                            <label className="montra-info-content-description">{props.info.contentLocalName}</label>
                            <label className="montra-info-content-description">{props.info.contentLocalAddress}</label>
                            {regexCoord.test(props.info.GPS_Lat) && regexCoord.test(props.info.GPS_Lng) ?
                                <div style={{ marginTop: '10px' }}>
                                    <Link to={{ pathname: '/mapa', state: props.info }} style={{ cursor: 'pointer', fontWeight: 600, fontSize: '14px', color: '#676767', border: '2px solid #676767', borderRadius: '15px', padding: '5px 20px' }}>
                                        <label style={{ cursor: 'pointer' }}>{mapLabel[languagePosition_forArray]}</label>
                                    </Link>
                                </div> : <></>}
                        </div>

                    </div>
                    : <></>
                }
                {props.info.synopsis !== null && props.info.synopsis !== "" ?
                    <div className="montra-info-content-item">
                        <div style={{ marginRight: '10px' }}>
                            <img src={images('./icone_descrição_sinopse.png')} alt="" />
                        </div>
                        <div>
                            <label className="montra-info-content-title">{synopsisLabel[languagePosition_forArray]}</label>
                            <label className="montra-info-content-description">{props.info.synopsis}</label>
                        </div>
                    </div>
                    : <></>
                }
                {props.info.contentContacts !== null && props.info.contentContacts.length > 0 ?
                    <div className="montra-info-content-item">
                        <div style={{ marginRight: '10px' }}>
                            <img src={images('./icone_descrição_contactos.png')} alt="" />
                        </div>
                        <div>
                            <label className="montra-info-content-title">{contactsLabel[languagePosition_forArray]}</label>
                            {props.info.contentContacts.map(contact => <label key={contact.id} className="montra-info-content-description">{contact.contactFormatName + ": " + contact.contact}</label>)}
                        </div>
                    </div> : <></>}

                {props.info.startDateString !== null && props.info.startDateString !== "" ?
                    <div className="montra-info-content-item">
                        <div style={{ marginRight: '10px' }}>
                            <img src={images('./icone_descrição_datainicio.png')} alt="" />
                        </div>
                        <div>
                            <label className="montra-info-content-title">{startDateLabel[languagePosition_forArray]}</label>
                            <label className="montra-info-content-description">{props.info.startDateString}</label>
                        </div>
                    </div>
                    : <></>
                }

                {props.info.endDateString !== null && props.info.endDateString !== "" ?
                    <div className="montra-info-content-item">
                        <div style={{ marginRight: '10px' }}>
                            <img src={images('./icone_descrição_datafim.png')} alt="" />
                        </div>
                        <div>
                            <label className="montra-info-content-title">{endDateLabel[languagePosition_forArray]}</label>
                            <label className="montra-info-content-description">{props.info.endDateString}</label>
                        </div>
                    </div>
                    : <></>
                }

                {props.info.availableArea !== null && props.info.availableArea !== "" ?
                    <div className="montra-info-content-item">
                        <div style={{ marginRight: '10px' }}>
                            <img src={images('./icone_descrição_area.png')} alt="" />
                        </div>
                        <div>
                            <label className="montra-info-content-title">{availableAreaLabel[languagePosition_forArray]}</label>
                            <label className="montra-info-content-description">{props.info.availableArea}</label>
                        </div>
                    </div>
                    : <></>
                }

                {props.info.capacity !== null && props.info.capacity !== "" ?
                    <div className="montra-info-content-item">
                        <div style={{ marginRight: '10px' }}>
                            <img src={images('./icone_descrição_lotação.png')} alt="" />
                        </div>
                        <div>
                            <label className="montra-info-content-title">{capacityLabel[languagePosition_forArray]}</label>
                            <label className="montra-info-content-description">{props.info.capacity}</label>
                        </div>
                    </div>
                    : <></>
                }

                {props.info.TicketOfficeInformation !== null && props.info.TicketOfficeInformation !== "" && props.info.TicketOfficeInformation.information !== "" ?
                    <div className="montra-info-content-item" style={{ marginBottom: "10px" }}>
                        <div style={{ marginRight: '10px' }}>
                            <img src={images('./icone_descrição_bilheteira.png')} alt="" />
                        </div>
                        <div>
                            <label className="montra-info-content-title">{ticketOfficeLabel[languagePosition_forArray]}</label>
                            <label className="montra-info-content-description">{props.info.TicketOfficeInformation.information}</label>
                        </div>
                    </div>
                    : <></>}

                {props.info.webSite !== null && props.info.webSite !== "" ?
                    <div className="montra-info-content-item" style={{ marginBottom: "10px" }}>
                        <div style={{ marginRight: '10px' }}>
                            <img src={images('./icone_descrição_site.png')} alt="" />
                        </div>
                        <div>
                            <label className="montra-info-content-title">Website</label>
                            {props.info.id === 1909 ? <label className="montra-info-content-description">Programa e formulário de inscrição:</label> : <></>}
                            {props.info.webSite.split('http').length > 1 ?
                                props.info.webSite.split('http').map((link, i) => (
                                    link !== "" ? <label key={i} className="montra-info-content-description"><a className="montra-info-content-description" rel="noopener noreferrer nofollow" target="_blank" href={'http' + link}>{'http' + link}</a></label> : <div key={i}></div>
                                ))
                                :
                                <label className="montra-info-content-description"><a className="montra-info-content-description" rel="noopener noreferrer nofollow" target="_blank" href={'//' + props.info.webSite}>{props.info.webSite}</a></label>
                            }
                        </div>
                    </div>
                    : <></>}

            </div>

        </>
    )
}

export const MontraDetailsAdditionalInformation = props => {

    const labelTitle = ["Informação adicional", "Additional information"];

    const [isOpen, setIsOpen] = useState(true);

    return (
        <>
            {props.info.additionalInformation != null && props.info.additionalInformation !== "" ?
                <>
                    <div className={isOpen ? "montra-info-header montra-info-header-active" : "montra-info-header"} onClick={() => setIsOpen(!isOpen)}>
                        <label>{labelTitle[languagePosition_forArray]}</label>
                        {
                            isOpen ? <IoIosArrowUp size={25} /> : <IoIosArrowDown size={25} />
                        }
                    </div>
                    <div className="montra-info-content" style={{ display: isOpen ? "" : "none" }}>
                        {props.info.additionalInformation.split('http').length > 1 ?
                            props.info.additionalInformation.split('http').map((link, i) => (
                                i === 0 ? <label key={i} className="montra-info-content-description">{link}</label>
                                    : <label key={i} className="montra-info-content-description">{'http' + link}</label>
                            ))
                            :
                            <label className="montra-info-content-description">{Parser(props.info.additionalInformation)}</label>
                        }

                    </div>

                </> : <></>
            }
        </>
    )
}

export const MontraDetailsMultimediaContent = props => {

    const [isOpen, setIsOpen] = useState(true);
    const labelTitle = ["Conteúdo áudio/video", "Audio/video content"];

    return (
        <>
            {props.info.adicionalFilesInfo !== null && props.info.adicionalFilesInfo.length > 0 ?
                <>
                    <div className={isOpen ? "montra-info-header montra-info-header-active" : "montra-info-header"} onClick={() => setIsOpen(!isOpen)}>
                        <label>{labelTitle[languagePosition_forArray]}</label>
                        {
                            isOpen ? <IoIosArrowUp size={25} /> : <IoIosArrowDown size={25} />
                        }
                    </div>
                    {props.info.adicionalFilesInfo.map(files => (
                        <div key={files.id} className="montra-info-content" style={{ display: isOpen ? "" : "none" }}>
                            <div className="montra-info-content-media">
                                <img src={files.link} style={{ backgroundColor: '#b2b2b2' }} width="135" height="70" alt="" />
                                <div>
                                    <label className="montra-info-content-media-title">{files.title}</label>
                                    <label className="montra-info-content-media-description">{files.description}</label>
                                </div>
                            </div>
                        </div>
                    ))}
                </> : <></>}

        </>
    )
}
