import React from 'react';
import { BrowserRouter, Route, Switch, Redirect,  } from 'react-router-dom';
import Home from './pages/Home/Home';
import Montra from './pages/Montra/Montra';
import Schedule from './pages/Schedule';
import Montra_Details from './pages/Montra/Montra_Details'
import Video from './pages/Vimeo/Vimeo';
//import Maps from './pages/Maps/Maps';
import Contacts from './pages/Contacts/Contacts';
import Information from './pages/Information';
import Preview from './pages/Preview/Preview';
import CookieConsent from "react-cookie-consent";
import GoogleApiWrapper from './pages/Maps/MapContainer';
//import Patrimonio from './pages/Património/Patrimonio';



export default function Routes() {

    return (
        <>
            <BrowserRouter>
                <Switch>
                    <Route path="/home" component={Home} />
                    <Route path="/showcase" component={Montra} />
                    <Route path="/details" component={Montra_Details} />
                    <Route path="/patrimony" component={Montra} />
                    <Route path="/museums" component={Montra} />
                    <Route path="/culturaleEuipment" component={Montra} />
                    <Route path="/publicArt" component={Montra} />
                    <Route path="/schedule" component={Schedule} />
                    <Route path="/artists" component={Montra} />
                    <Route path="/buildings" component={Montra} />
                    <Route path="/archive" component={Montra} />
                    <Route path="/video" component={Video} />
                    {/*<Route path="/maps" component={Maps} />*/}
                    <Route path="/mapa" component={GoogleApiWrapper} />
                    <Route path="/contacts" component={Contacts} />
                    <Route path="/information" component={Information} />
                    <Route path="/preview" component={Preview} />
                    <Route path='/' render={() => <Redirect to="/home" />} />
                </Switch>
            </BrowserRouter>
            <CookieConsent
                location="bottom"
                buttonText="Aceitar"
                cookieName="cookieconsent_status"
                style={{ background: "#000", color:'#fff' }}
                buttonStyle={{ color: "#000", background:'#fef102' }}
                expires={150}
            >Este site utiliza cookies necessários e analíticos para permitir uma melhor experiência e melhorar o serviço prestado. Ao clicar aceitar consente na utilização dos cookies analíticos. Pretende prosseguir? 
            <a aria-label="learn more about cookies" role="button" tabIndex="0" className="cc-link" href="https://www.aml.pt/index.php?cMILID=SUS5E627990B0F88&amp;cMILL=2&amp;mIID=SUS5E6279638E2EC&amp;mIN=Pol%EDtica%20de%20Privacidade%20e%20Seguran%E7a&amp;mILA=&amp;cMILID1=SUS5787A239AC92B&amp;mIID1=0&amp;mIN1=Institucional&amp;cMILID2=SUS5E627990B0F88&amp;mIID2=SUS5E6279638E2EC&amp;mIN2=Pol%EDtica%20de%20Privacidade%20e%20Seguran%E7a" rel="noopener noreferrer nofollow" target="_blank">Política de cookies</a>
            </CookieConsent>
        </>
    )
}