import React from 'react';
import Footer from '../Footer';
import Header from '../Header';
import Loader from '../Loader';
import { GoogleApiWrapper, InfoWindow, Marker, Map, } from 'google-maps-react';
import { FO_MapsInfo_GetAll, images, languagePosition_forArray } from '../Utils';

export class MapContainer extends React.Component {
  state = {
    currentLocationMsg: ["Posição Atual", "Current Location"],
    menuName: ["Mapa","Map" ],

    showingInfoWindow: false,
    activeMarker: {},
    selectedPlace: {},
    loading: true,
    markersObj: [],
    center_lat: this.props.location.state ? this.props.location.state.GPS_Lat : 38.70761,
    center_lng: this.props.location.state ? this.props.location.state.GPS_Lng : -9.16893,
    zoom: this.props.location.state ? 18 : 10,
    Name: this.props.location.state ? this.props.location.state.name : null,
  };

  onMarkerClick = (props, marker, e) => {
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
    });
  }

  onClose = props => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      });
    }
  };

  componentDidMount() {
    FO_MapsInfo_GetAll().then(result => {
      if (result.status === 200) {
        this.setState({
          markersObj: result.data.FO_MapsInfo_GetAllResult,
        });
      }
      this.setState({ loading: false });
    });
  }

  renderMarker(card) {
    switch (card.contentTypeID) {
      case 1:
        return <Marker key={card.id} onClick={this.onMarkerClick} id={card.id} address={card.address} title={card.name} name={card.name} position={{ lat: card.gps_Lat, lng: card.gps_lng }} icon={{ url: images('./pin_montra_1.png') }}/>;
      case 2:
        return <Marker key={card.id} onClick={this.onMarkerClick} id={card.id} address={card.address} title={card.name} name={card.name} position={{ lat: card.gps_Lat, lng: card.gps_lng }} icon={{ url: images('./pin_patrimonio_1.png') }}/>;
      case 3:
        return <Marker key={card.id} onClick={this.onMarkerClick} id={card.id} address={card.address} title={card.name} name={card.name} position={{ lat: card.gps_Lat, lng: card.gps_lng }} icon={{ url: images('./pin_museus_1.png') }}/>;
      case 4:
        return <Marker key={card.id} onClick={this.onMarkerClick} id={card.id} address={card.address} title={card.name} name={card.name} position={{ lat: card.gps_Lat, lng: card.gps_lng }} icon={{ url: images('./pin_equipamentosculturais_1.png') }}/>;
      case 5:
        return <Marker key={card.id} onClick={this.onMarkerClick} id={card.id} address={card.address} title={card.name} name={card.name} position={{ lat: card.gps_Lat, lng: card.gps_lng }} icon={{ url: images('./pin_edificios_1.png') }}/>;
      case 8:
        return <Marker key={card.id} onClick={this.onMarkerClick} id={card.id} address={card.address} title={card.name} name={card.name} position={{ lat: card.gps_Lat, lng: card.gps_lng }} icon={{ url: images('./pin_artepublica_1.png') }}/>;
      default:
        return <Marker key={card.id} onClick={this.onMarkerClick} id={card.id} address={card.address} title={card.name} name={card.name} position={{ lat: card.gps_Lat, lng: card.gps_lng }} />;
    }
  }

  render() {
    return (
      <>
        {this.state.zoom === 18 ? <Header hasTitle={true} title={this.state.Name} showSideBar={true} GoBackOnTitle={true} /> : <Header hasTitle={true} title={this.state.menuName[languagePosition_forArray]} showSideBar={true} GoBackOnTitle={true} />}
        {this.state.loading ? <Loader /> : <></>}

        <div className="maps-div" style={{ position: 'relative' }}>
          <Map google={this.props.google} style={{ width: '100%', height: '100%', position: 'relative' }} className={'map'} zoom={this.state.zoom} initialCenter={{ lat: this.state.center_lat, lng: this.state.center_lng }}>

            {this.state.markersObj.map((card) => (
              this.renderMarker(card)
            ))}

            <InfoWindow
              marker={this.state.activeMarker}
              visible={this.state.showingInfoWindow}
              onClose={this.onClose}
            >
              <div style={{ background: '#fff', margin: 'auto', display: 'flex' }}>
                <div style={{ padding: '15px 10px 10px 15px', }}>
                  <label style={{ color: '#1d1d1b', fontSize: '20px', fontWeight: 'bold', width: "100%" }}>{this.state.selectedPlace.name}</label>
                  <label style={{ color: '#888', fontSize: '18px', fontWeight: 'regular', width: "100%" }}>{this.state.selectedPlace.address}</label>
                </div>
                <div style={{ width: '40px', margin: 'auto' }}>
                  <a href={'/details/' + this.state.selectedPlace.id}>
                    <img style={{ width: '40px', height: '40px' }} src={images('./icon_info_2.png')} alt="information" />
                  </a>
                </div>
              </div>
            </InfoWindow>
          </Map>
        </div>
        <Footer />
      </>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyDjzLYz4DpNfpF0g3JOMxCPRsjs06MOVqM', //DEMO/PRD
  //apiKey: 'AIzaSyDlh4EB1lFpAF_zMkNusfJEXSI3WeCVT8M', //DEV 
  language: 'pt'
})(MapContainer);
