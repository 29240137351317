import React, { useState, useEffect } from 'react'
import Footer from '../Footer';
import Header from '../Header';
import SliderWithoutInfo from '../Slider/SliderWithoutInfo';
import { languagePosition_forArray, LanguageID } from '../Utils';
import Loader from '../Loader';
import { useHistory } from 'react-router-dom';
import { MontraDetailsGeral, MontraDetailsAdditionalInformation, MontraDetailsMultimediaContent } from '../Montra/Montra_Details';

export default function Preview(props) {

    const history = useHistory();
    const [data, setData] = useState();
    const [response, setResponse] = useState();

    useEffect(() => {
        const errorPage = ["Não foi possivel abrir a pagina em questão.", "It was not possible to open the page in question."];
        const langID = props.location.search.charAt(1);
        if (LanguageID() !== parseInt(langID)) {
            localStorage.setItem('LanguageID', parseInt(langID));
            window.location.reload(false);
        } else {
            const obj = props.location.search.substring(2);
            if (obj !== "" && obj !== null) {
                var aux = decodeURIComponent(obj);
                var aux2 = JSON.parse(aux);
                setData(aux2);
                setResponse(aux2);
            } else {
                alert(errorPage[languagePosition_forArray]);
                history.push({ pathname: '/home' });
            }
        }
    }, [props.location, history]);

    return (
        <>{data !== undefined ?
            <>
                <Header hasTitle={true} title={data.contentCategoryName !== "" && data.contentCategoryName !== null ? data.contentCategoryName : ""}
                    showSideBar={true} GoBackOnTitle={'/showcase'} Removebuttons={true} />
                <div className="main">
                    <SliderWithoutInfo info={data} responseData={response} />

                    <div className="container" style={{ marginTop: '20px', marginBottom: '20px' }}>
                        <MontraDetailsGeral info={data} />
                        <MontraDetailsAdditionalInformation info={data} />
                        <MontraDetailsMultimediaContent info={data} />
                    </div>
                </div>
                <Footer />
            </>
            :
            <>
                <Header hasTitle={true} title={""} showSideBar={true} GoBackOnTitle={'/showcase'} />
                <div className="main">
                    <Loader />
                </div>
                <Footer />
            </>
        }
        </>
    );
}