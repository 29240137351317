import React from 'react'
import Footer from './Footer';
import Header from './Header';
import Slider from './Slider/Slider';
import Loader from './Loader';
import { withRouter } from 'react-router-dom';
import { images, menus, GetHighlightMini_ForFront_Paginate, GetMini_ForSchedule, languagePosition_forArray } from './Utils';




class Schedule extends React.Component {
    state = {
        errorHigilight: ["Não foi possivel obter os destaques.","It was not possible to get the highlights."],
        errorContent: ["Não foi possivel obter conteúdos.","It was not possible to obtain content."],
        noResults: ["Não existem resultados disponíveis.","There are no results available."],

        menuObject: menus.filter((e) => e.url === this.props.location.pathname)[0],
        hilight_Cards: null,
        content_Cards: null,
        loading: true,
    }

    componentDidMount() {
        GetHighlightMini_ForFront_Paginate(0).then(result => {
            if (result.status === 200) {
                this.setState({
                    loading: false,
                    hilight_Cards: result.data.GetHighlightMini_ForFront_PaginateResult,
                });
            } else {
                this.setState({
                    loading: false,
                });
                alert(this.state.errorHigilight[languagePosition_forArray])
            }
        });

        GetMini_ForSchedule().then(result => {
            if (result.status === 200){
                this.setState({
                    content_Cards: result.data.GetMini_ForScheduleResult,
                    loading: false,
                });
            } else {
                this.setState({
                    loading: false,
                });
                alert(this.state.errorContent[languagePosition_forArray])
            }
        });
    }

    componentWillUnmount() {
    }

    handleRowClicked = (e) => {
        window.open(e.website, '_blank');
    }

    render() {
        return (
            <>
                <Header hasTitle={true} title={this.state.menuObject.name[languagePosition_forArray]} showSideBar={true} GoBackOnTitle={'/home'} />
                <div className="main">
                {this.state.hilight_Cards != null && this.state.hilight_Cards.length > 0 ?
                                    <Slider cards={this.state.hilight_Cards} homepage={true} />
                                    : <></>}
                    {this.state.loading ? <Loader /> : <></>}
                    <div className="container montra-card-wrapper">

                        {this.state.content_Cards !== null && this.state.content_Cards.length > 0 ?
                            <div className="row montra-card-div">
                                {this.state.content_Cards.map((card) => (
                                    <div key={card.id} className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 montra-card-child-wrapper">
                                        <button className="montra-card-child" onClick={() => this.handleRowClicked(card)}>
                                            <div className="montra-card">
                                                <div className="row montra-card-shadow">
                                                    <div className="montra-card-img col-xl-12 col-lg-12 col-md-12 col-sm-6 col-4" style={{backgroundColor: '#f5f5f5'}}>
                                                        <img prop="logo" alt="logo" style={{ width:'100%',height:'100%', backgroundRepeat: "no-repeat", backgroundPosition: 'center', objectFit: 'contain' }} src={card.presentationImages.link} />
                                                    </div>
                                                    <div className="montra-card-label col-xl-12 col-lg-12 col-md-12 col-sm-6 col-8" style={{minHeight:'80px'}}>
                                                        <label className="montra-card-label-title">{card.name}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </button>
                                    </div>
                                ))}
                            </div>
                            :
                            <div style={{ textAlign: "center", padding: "30px 0px" }}>
                                <img src={images('./icone_sem-resultados.png')} alt="Não existem resultados disponíveis" />
                                <label style={{ color: "#a3a3a3", fontSize: "18px", width: "100%", paddingTop: "15px" }}>{this.state.noResults[languagePosition_forArray]}</label>
                            </div>
                        }
                    </div>
                </div>
                <Footer />
            </>
        );
    }
}




export default withRouter(Schedule);