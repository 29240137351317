import React, { useEffect, useState } from 'react';
import Parser from 'html-react-parser'
import Header from '../Header';
import Footer from '../Footer';
import { languagePosition_forArray } from '../Utils';
import ReactPlayer from 'react-player'

export default function Video(props) {

    const errorLabel = ["Não foi possivel abrir a pagina em questão.", "It was not possible to open a page in question."];
    const onlineTransmission= ["TRANSMISSÃO","STREAMING"];
    const [embed, setEmbed] = useState('');
    const [link, setLink] = useState('');
    const data = props.location.state;


    useEffect(() => {
        if (data != null) {
            if (data.embed != null) {
                setEmbed(data.embed.html);
            }
            if (data.linkVideo !== null && data.linkVideo !== "") {
                setLink(data.linkVideo);
            }
        } else {
            alert(errorLabel[languagePosition_forArray])
            window.location = "/home";
        }
    }, [data, errorLabel]);


    return (
        <>
            <Header hasTitle={true} title={onlineTransmission[languagePosition_forArray]} showSideBar={true} GoBackOnTitle={true} />
            <div className="main vimeo">
                {embed !== '' ? Parser(embed) : link !== "" ?
                    <ReactPlayer url={link} width={'100%'} height={'100%'}  controls={true} />
                    : <></>
                }

            </div>
            <Footer />
        </>
    )
}